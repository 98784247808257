import { useTranslation } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";
import { FC } from "react";
import styles from "./Error.module.scss";

interface Props {
  onRetry?: () => void;
}

export const GenericError: FC<Props> = ({ onRetry }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h5>{t("Ooh noo")}! 😔</h5>
      <p className={styles.message}>{t("Something bad happened. Try again?")}</p>
      {onRetry && (
        <Button onClick={onRetry} block>
          {t("Try again?")}
        </Button>
      )}
    </div>
  );
};
