import React, { ReactNode, useCallback, useRef, useState } from "react";
import cx from "classnames";
import { Dynamic, Status } from "../../../data/types";
import { id as generateId } from "../../utils";
import "./Base.scss";
import "./Input.scss";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Pending } from "../../icons/Pending";
import { Error } from "../../icons/Error";

export interface PreIcon {
  icon: string | ReactNode;
}

export interface Props {
  status?: Status;
  className?: string;
  value?: string;
  placeholder?: string;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  onChange: (value: string, name: string, ev?: InputEvent) => void;
  onBlur?: (value: string, name: string) => void;
  onFocus?: (value: string) => void;
  name?: string;
  message?: string | React.ReactNode;
  autoFocus?: boolean;
  type?: string;
  attributes?: Dynamic;
  list?: string;
  required?: boolean;
  autocomplete?: string;
  id?: string;
  onKeyUp?: (keyCode: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean) => void;
  preIcon?: PreIcon;
}

export interface StatusMessage {
  status: Status;
  message?: ReactNode | string;
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      status = Status.DEFAULT,
      className,
      name,
      label = null,
      value = "",
      onChange,
      onBlur,
      onFocus,
      message = null,
      hint = null,
      placeholder = "",
      autoFocus,
      type,
      attributes = {},
      list,
      required,
      autocomplete,
      id,
      onKeyUp,
      preIcon = undefined,
      ...props
    },
    ref
  ) => {
    //Det här kanske är fugly att dra in externt beroende, men de finns en textsträng här som jag måste komma åt för att översätta..
    //Ett alternativ kanske är att göra det till en icon istället?
    const identifier = useRef<string>(id ?? generateId());
    const prevValue = useRef<string | undefined>(value);
    const [internalStatus, setInternalStatus] = useState<StatusMessage>({
      status: Status.DEFAULT,
    });

    const change = useCallback(
      (ev) => {
        onChange(ev.target.value, name || identifier.current, ev);
        setTimeout(() => {
          setInternalStatus((prevStatus) => ({
            ...prevStatus,
            status: Status.DEFAULT,
          }));
        }, 0);
      },
      [onChange, name]
    );

    const focus = useCallback(() => {
      prevValue.current = value;
      onFocus && onFocus(value);
    }, [value, onFocus]);

    const blur = useCallback(() => {
      // This is so ugly, but will fix that with new input components
      // If the datepicker is used prevValue will correspond to our
      // latest value
      // console.log("blur", value);
      if (type === "date") {
        onBlur && onBlur(value, name || identifier.current);
        return;
      }

      if (prevValue.current === value) {
        return;
      }

      onBlur && onBlur(value, name || identifier.current);
    }, [onBlur, value, name, type]);

    const keyUp = useCallback(
      (ev) => {
        onKeyUp && onKeyUp(ev.keyCode, ev.ctrlKey, ev.shiftKey, ev.altKey);
      },
      [onKeyUp]
    );

    const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = useCallback(
      (ev) => {
        ev.preventDefault();
        const pastedText = ev.clipboardData.getData("text");
        // Trim trailing spaces from the pasted text
        const trimmedText = pastedText.trimEnd();
        change({ target: { value: trimmedText } });
      },
      [change]
    );

    const dynamicProps: Dynamic = attributes;

    if (type) {
      dynamicProps.type = type;
    }

    if (list) {
      dynamicProps.list = list;
    }

    let consolidatedMessage = message;
    let consolidatedStatus = status;
    if (status === Status.DEFAULT) {
      consolidatedStatus = internalStatus.status;
      consolidatedMessage = internalStatus.message || "";
    }

    return (
      <label
        className={cx("input", "input-text", className, consolidatedStatus, {
          "has-pre-icon": !!preIcon,
        })}
        htmlFor={identifier.current}
      >
        <div className="input-label-wrapper">
          <div className="input-label">
            <div className="input-label-tag">{label || <div className="invisible">empty</div>}</div>
          </div>
          {!value && required && <div className="required-marker fs-small"></div>}
        </div>

        <div className="input-frame">
          {preIcon?.icon && <div className="pre-icon">{preIcon.icon}</div>}
          <input
            name={name || identifier.current}
            id={id}
            value={value}
            onChange={change}
            onBlur={blur}
            onFocus={focus}
            placeholder={placeholder}
            disabled={status === Status.DISABLED}
            autoFocus={autoFocus}
            autoComplete={autocomplete}
            onKeyUp={keyUp}
            onPaste={handlePaste}
            ref={ref}
            {...dynamicProps}
            {...props}
          />

          <div className="input-status">
            <Checkmark />
            <Disabled />
            <Pending />
            <Error />
          </div>
        </div>

        {(hint || consolidatedMessage) && (
          <div className="input-messages">
            {consolidatedMessage ? (
              <div className="input-message">{consolidatedMessage}</div>
            ) : (
              <div className="input-hint">{hint}</div>
            )}
          </div>
        )}
      </label>
    );
  }
);
