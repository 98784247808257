import { ErrorBoundary } from "@sentry/react";
import cx from "classnames";
import { DateTime, Settings } from "luxon";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, RouteProps, Switch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { LogoutWarning } from "./components/logoutWarning/LogoutWarning";
import { RouteListener } from "./components/RouteListener";
import { Spinner } from "./components/spinner/Spinner";
import { TranslationManagement } from "./components/translation/TranslationManagement";
import { Role, SessionResponse, dataAuth } from "./data/dataAuth";
import i18n from "./i18n";
import { AdminDashboardPage, ADMIN_DASHBOARD_ROUTE } from "./pages/Admin/AdminDashboardPage";
import { ContractAuditPage, CONTRACT_AUDIT_PAGE } from "./pages/Admin/Audit/AdminContractAuditLog";
import {
  COMMUNICATION_LIST_URL,
  CommunicationList,
} from "./pages/Admin/Communication/communicationList/CommunicationList";
import { CommunicationTester, COMMUNICATION_TESTER } from "./pages/Admin/Communication/CommunicationTester";
import { EMAIL_PREVIEW_URL, EmailPreview } from "./pages/Admin/Communication/emailPreview/EmailPreview";
import { TEXT_PREVIEW_URL, TextPreview } from "./pages/Admin/Communication/textPreview/TextPreview";
import {
  AdminContractListPage,
  ADMIN_CONTRACTS_LIST_PAGE,
} from "./pages/Admin/Contracts/AdminContractsListPage";
import { ArchivePage, ARCHIVE_PAGE } from "./pages/Admin/Contracts/ArchivePage";
import { FixContractPage, FIX_CONTRACT_PAGE } from "./pages/Admin/Contracts/FixContractPage";
import { FixViesBySettingItInDbPage, VIES_PAGE } from "./pages/Admin/Contracts/FixViesBySettingItInDbPage";
import { FixViesPage, FIX_VIES_PAGE } from "./pages/Admin/Contracts/FixViesPage";
import { EditMccPage, MCC_EDIT_PAGE } from "./pages/Admin/Mcc/old/EditMccPage";
import { MccAdministrationPage, MCC_ADMIN_PAGE } from "./pages/Admin/Mcc/MccAdministrationPage";
import { PassFileUploadPage, PASS_FILE_UPLOAD_PAGE } from "./pages/Admin/Pass/PassFIleUpload";
import {
  NewAdminPresetPage,
  ADMIN_NEW_PRICING_PAGE,
} from "./pages/Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/NewAdminPreSetPage";
import {
  AdminPricingCountryPage,
  ADMIN_PRICING_COUNTRY_PAGE,
} from "./pages/Admin/Pricing/AdminPricingCountryPage/AdminPricingCountryPage";
import {
  AdminPricingPage,
  ADMIN_PRICING_PAGE,
} from "./pages/Admin/Pricing/AdminPricingPage/AdminPricingPage";
import {
  EditAdminPresetPage,
  ADMIN_PRICING_PRESET_PAGE,
} from "./pages/Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/EditAdminPreSetPage";
import { SalesTeamPage, SALES_TEAM_EDIT_PAGE } from "./pages/Admin/Teams/SalesTeamPage";
import { SalesTeamsOverview, SALES_TEAM_OVERVIEW_PAGE } from "./pages/Admin/Teams/SalesTeamsOverview";
import { AddTranslation, ADD_TRANSLATION_PAGE } from "./pages/Admin/Translation/AddTranslation";
import { MissingTranslationsPage, TRANSLATIONS_PAGE } from "./pages/Admin/Translation/MissingTranslations";
import { AdminUsersPage, SUPER_ADMIN_USERS_PAGE } from "./pages/Admin/Users/AdminUsers";
import { SalesUserDetailsPage, SALES_USER_EDIT_PAGE } from "./pages/Admin/Users/SalesUserPage";
import { SalesUsersPage, SALES_ADMIN_USERS_PAGE } from "./pages/Admin/Users/SalesUsers";
import { AnalyticsPage, ANALYTICS_ROUTE } from "./pages/Analytics/AnalyticsPage";
import { ApplicationPage, APPLICATION_ROUTE } from "./pages/Application/ApplicationPage";
import { AssessmentsPage, ASSESSMENTS_ROUTE } from "./pages/Assessments/AssessmentsPage";
import { LoginPage } from "./pages/Auth/LoginPage";
import { Logout, LOGOUT_ROUTE } from "./pages/Auth/Logout";
import {
  BackofficeCompletedPage,
  BACKOFFICE_COMPLETE_PAGE,
} from "./pages/CompletedContract/BackofficeCompletedPage";
import {
  CompletedContractPage,
  COMPLETED_CONTRACT_PAGE,
} from "./pages/CompletedContract/CompletedContractPage";
import { ContractPage, CONTRACT_ROUTE } from "./pages/Contract/ContractPage";
import { DashboardPage, DASHBOARD_ROUTE } from "./pages/Dashboard/DashboardPage";
import { HiddenClaimPage, HIDDEN_CLAIM_ROUTE } from "./pages/HiddenClaim/HiddenClaimPage";
import {
  AssociateReviewPage,
  ASSOCIATE_REVEW_PAGE,
} from "./pages/MerchantStatus/AssociateReview/AssociateReviewPage";
import { MerchantStatusPage, MERCHANT_ROUTE } from "./pages/MerchantStatus/MerchantStatusPage";
import { NotFoundPage } from "./pages/Page/NotFoundPage";
import { OfferingsPage, OFFERINGS_ROUTE } from "./pages/Offerings/OfferingsPage";
import { FeatureTogglePage, FEATURE_TOGGLE_PAGE } from "./pages/Secret/FeatureTogglePage";
import {
  FeatureTranslationTogglePage,
  FEATURE_TRANSLATION_TOGGLE_PAGE,
} from "./pages/Secret/FeatureTranslationTogglePage";
import { PeriodicReportPage, PERIODIC_REPORT_PAGE } from "./pages/Secret/PeriodicReportPage";
import { Styleguide } from "./pages/Styleguide/Styleguide";
import { contractStatusState } from "./state/contractStatusState";
import { translationManagementState } from "./state/translationState";
import { themeState, viewState } from "./state/uiState";
import { userState } from "./state/userState";
import { HIDDEN_ROUTE, HiddenPage } from "./pages/Page/HiddenPage";
import { AnimatePresence } from "framer-motion";
import {
  BACKOFFICE_DASHBOARD_ROUTE,
  BackofficeDashboardPage,
} from "./pages/BackOffice/BackofficeDashboardPage";
import { BACKOFFICE_ADMIN_USERS_PAGE, BackOfficeUsersPage } from "./pages/BackOffice/BackOfficeUsers";
import { Store, STORE_KEY } from "./Store";
import {
  CORRELATION_ID_ROUTING_PAGE,
  CorrelationIdRoutingPage,
} from "./pages/Salesforce/CorrelationIdRoutingPage";

interface Props extends RouteProps {
  role?: Role;
}

Settings.defaultLocale = i18n.language;

const SecureRoute: React.FunctionComponent<Props> = ({ role, exact, path, component, children }) => {
  const user = useRecoilValue(userState);

  if (!user) {
    return <Route component={LoginPage} />;
  }

  if (role && user.role !== role) {
    return <Route component={LoginPage} />;
  }

  const isPast = DateTime.now() > DateTime.fromISO(user.expiry);
  if (isPast) {
    return <Route component={LoginPage} />;
  }

  // <Redirect
  //   to={{
  //     pathname: LOGIN_ROUTE,
  //     state: { from: props.location?.pathname },
  //   }}
  // />
  return <Route {...{ component, children, exact, path }} />;
};

function App() {
  const theme = useRecoilValue(themeState);
  const view = useRecoilValue(viewState);
  const { country } = useRecoilValue(contractStatusState);
  const [user, setUser] = useRecoilState(userState);
  const [loadingUser, setLoadingUser] = useState(true);

  // const { active: isTranslationEditorActive } = useRecoilValue(
  //   translationManagementState
  // );

  useEffect(() => {
    const sessionUser = Store.getValue<SessionResponse>(STORE_KEY.STORE_USER);

    if (sessionUser == null) {
      setUser(null);
      return setLoadingUser(false);
    }

    const expiry = DateTime.fromISO(sessionUser.expiry);
    const sessionHasExpired = expiry.diffNow().milliseconds < 0;
    if (sessionHasExpired) {
      Store.setValue(STORE_KEY.STORE_USER, null);
      setLoadingUser(false);
      return setUser(null);
    }

    // fetch user on page refresh
    dataAuth
      .getCurrentSession()
      .then((resp) => {
        setUser(resp);
        Store.setValue(STORE_KEY.STORE_USER, resp);
      })
      .catch(() => console.log("Could not load user session"))
      .finally(() => setLoadingUser(false));
  }, [setUser]);

  const [{ active: isTranslationEditorActive }] = useRecoilState(translationManagementState);

  const isTranslationManagementActive =
    isTranslationEditorActive && user && (user.role === Role.ADMIN || user?.canSwitch);

  if (loadingUser) return <Spinner size="large" />;

  return (
    <BrowserRouter>
      <ErrorBoundary showDialog={false}>
        <Suspense
          fallback={
            <div className="loading">
              <Spinner size="large" />
            </div>
          }
        >
          <RouteListener />
          <LogoutWarning />
          <div className={cx(theme)}>
            <div id="backdrop-portal"></div>
            <div id="popover-portal"></div>
            <main className={cx("app", view, country)}>
              {isTranslationManagementActive && <TranslationManagement />}

              <AnimatePresence exitBeforeEnter>
                <Switch>
                  <SecureRoute
                    exact
                    path={HIDDEN_CLAIM_ROUTE}
                    component={HiddenClaimPage}
                    role={Role.SALES}
                  />

                  <SecureRoute exact path={DASHBOARD_ROUTE} component={DashboardPage} role={Role.SALES} />
                  <SecureRoute exact path={CONTRACT_ROUTE} component={ContractPage} />

                  <SecureRoute exact path={MERCHANT_ROUTE} component={MerchantStatusPage} />

                  <SecureRoute exact path={ASSOCIATE_REVEW_PAGE} component={AssociateReviewPage} />

                  <SecureRoute exact path={COMPLETED_CONTRACT_PAGE} component={CompletedContractPage} />

                  <SecureRoute exact path={APPLICATION_ROUTE} component={ApplicationPage} role={Role.SALES} />
                  <SecureRoute exact path={ASSESSMENTS_ROUTE} component={AssessmentsPage} role={Role.SALES} />
                  <SecureRoute exact path={OFFERINGS_ROUTE} component={OfferingsPage} role={Role.SALES} />
                  <SecureRoute exact path={ANALYTICS_ROUTE} component={AnalyticsPage} role={Role.SALES} />

                  <SecureRoute exact path={ARCHIVE_PAGE} component={ArchivePage} role={Role.ADMIN} />

                  <SecureRoute
                    exact
                    path={SALES_TEAM_EDIT_PAGE}
                    component={SalesTeamPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={SALES_TEAM_OVERVIEW_PAGE}
                    component={SalesTeamsOverview}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={BACKOFFICE_DASHBOARD_ROUTE}
                    component={BackofficeDashboardPage}
                    role={Role.BACKOFFICE}
                  />

                  <SecureRoute
                    exact
                    path={ADMIN_DASHBOARD_ROUTE}
                    component={AdminDashboardPage}
                    role={Role.ADMIN}
                  />
                  <SecureRoute
                    exact
                    path={SUPER_ADMIN_USERS_PAGE}
                    component={AdminUsersPage}
                    role={Role.ADMIN}
                  />
                  <SecureRoute
                    exact
                    path={BACKOFFICE_ADMIN_USERS_PAGE}
                    component={BackOfficeUsersPage}
                    role={Role.ADMIN}
                  />
                  <SecureRoute
                    exact
                    path={SALES_ADMIN_USERS_PAGE}
                    component={SalesUsersPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={CONTRACT_AUDIT_PAGE}
                    component={ContractAuditPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={SALES_USER_EDIT_PAGE}
                    component={SalesUserDetailsPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={MCC_ADMIN_PAGE}
                    component={MccAdministrationPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute exact path={MCC_EDIT_PAGE} component={EditMccPage} role={Role.ADMIN} />

                  <SecureRoute
                    exact
                    path={COMMUNICATION_TESTER}
                    component={CommunicationTester}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={TRANSLATIONS_PAGE}
                    component={MissingTranslationsPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={ADD_TRANSLATION_PAGE}
                    component={AddTranslation}
                    role={Role.ADMIN}
                  />

                  <SecureRoute exact path={FIX_CONTRACT_PAGE} component={FixContractPage} role={Role.ADMIN} />

                  <SecureRoute exact path={FIX_VIES_PAGE} component={FixViesPage} role={Role.ADMIN} />

                  <SecureRoute
                    exact
                    path={VIES_PAGE}
                    component={FixViesBySettingItInDbPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={PASS_FILE_UPLOAD_PAGE}
                    component={PassFileUploadPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={ADMIN_CONTRACTS_LIST_PAGE}
                    component={AdminContractListPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={COMMUNICATION_LIST_URL}
                    component={CommunicationList}
                    role={Role.ADMIN}
                  />

                  <SecureRoute exact path={EMAIL_PREVIEW_URL} component={EmailPreview} role={Role.ADMIN} />

                  <SecureRoute exact path={TEXT_PREVIEW_URL} component={TextPreview} role={Role.ADMIN} />

                  <SecureRoute exact path={FEATURE_TOGGLE_PAGE} component={FeatureTogglePage} />

                  <SecureRoute
                    exact
                    path={PERIODIC_REPORT_PAGE}
                    component={PeriodicReportPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={FEATURE_TRANSLATION_TOGGLE_PAGE}
                    component={FeatureTranslationTogglePage}
                  />

                  <Route exact path={BACKOFFICE_COMPLETE_PAGE} component={BackofficeCompletedPage} />

                  <SecureRoute
                    exact
                    path={ADMIN_NEW_PRICING_PAGE}
                    component={NewAdminPresetPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={ADMIN_PRICING_PRESET_PAGE}
                    component={EditAdminPresetPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={ADMIN_PRICING_COUNTRY_PAGE}
                    component={AdminPricingCountryPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={ADMIN_PRICING_PAGE}
                    component={AdminPricingPage}
                    role={Role.ADMIN}
                  />

                  <SecureRoute
                    exact
                    path={CORRELATION_ID_ROUTING_PAGE}
                    component={CorrelationIdRoutingPage}
                  />

                  <Route exact path={LOGOUT_ROUTE} component={Logout} />
                  <Route exact path="/styleguide" component={Styleguide} />
                  <Route exact path={["/", "/login"]} component={LoginPage} />
                  <Route exact path={HIDDEN_ROUTE} component={HiddenPage} />
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </AnimatePresence>
            </main>
          </div>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
