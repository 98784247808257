import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { DESKTOP_MIDDLE_WIDTH, DESKTOP_WIDTH, useWidth } from "../../hooks/useWidth";
import { AnimatePresence, motion } from "framer-motion";
import { Backdrop } from "../../components/backdrop/Backdrop";
import { Button } from "../../components/interactions/Buttons/Button";
import styles from "./Page.module.scss";
import { Burger } from "../../components/icons/Burger";
import { Sidebar } from "./Sidebar/Sidebar";

export enum PageType {
  SALES_DASHBOARD = "SALES_DASHBOARD",
  ADMIN = "ADMIN",
  SALES_CONTRACT = "SALES_CONTRACT",
}
export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  stripped?: boolean;
  pageType?: PageType;

  contentMaxWidth?: number;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  className,
  title,
  stripped = false,
  pageType = PageType.SALES_DASHBOARD,
  contentMaxWidth = DESKTOP_MIDDLE_WIDTH,
}) => {
  const [menuOpen, toggleMenu] = useState<boolean>(true);

  // const elemRef = useRef<HTMLDivElement>(null);
  const onOpen = useCallback(() => toggleMenu(true), [toggleMenu]);
  const onClose = useCallback(() => toggleMenu(false), [toggleMenu]);

  const width = useWidth();
  const isDesktop = width > DESKTOP_WIDTH;

  useEffect(() => {
    toggleMenu(isDesktop ? true : false);
  }, [isDesktop]);

  useEffect(() => {
    if (!title) {
      document.title = "WorldlineX";
      return;
    }
    document.title = `${title} | WorldlineX`;
  }, [title]);

  if (stripped) {
    return (
      <div className={cx(styles.root, className)}>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.page_stripped}
          style={{ maxWidth: contentMaxWidth }}
        >
          {children}
        </motion.section>
      </div>
    );
  }

  return (
    <div className={cx(styles.root, className)}>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ translateX: isDesktop ? 0 : "-100%" }}
            animate={{ translateX: 0 }}
            exit={{ translateX: "-100%" }}
            className={styles.sidebar_wrapper}
          >
            <Sidebar pageType={pageType} />
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.content}>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.section}
          style={{ maxWidth: contentMaxWidth }}
        >
          {children}
        </motion.section>
      </div>

      {!isDesktop && (
        <>
          <Backdrop open={menuOpen} onClick={onClose} variant="dark" />
          <Button action onClick={onOpen} className={styles.sidebar_button}>
            <Burger />
          </Button>
        </>
      )}
    </div>
  );
};
