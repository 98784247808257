export type ContractId = string & { isContractId: true };
export type SigneeId = number & { isSigneeId: true };
export type BeneficialOwnerId = number & { isOwnerId: true };
export type TerminalId = number & { isTerminalId: true };
export type StoreId = number & { isStoreId: true };
export type UserId = string & { isUserId: true };
export type TeamId = string & { isTeamId: true };
export type Cas = number & { isCasId: true };
export type LinkId = string & { isLinkId: true };
export type UTCDate = string & { isUTCDate: true };
export type DocumentId = number & { isDocumentId: true };
export type AssociateId = string & { isAssociateId: true };
export type CorrelationId = string & { isCorrelationId: true };

export enum Country {
  POLAND = "PL",
  CROATIA = "HR",
  CZECHIA = "CZ",
}

export enum Currency {
  POLAND = "PLN",
  CROATIA = "EUR",
  CZECHIA = "CZK",
}
