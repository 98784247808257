import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  isOwner as isOwnerFn,
  isPrimary as isPrimaryFn,
  isSignee as isSigneeFn,
  hasLogin as hasLoginFn,
  isAccountHolder as isAccountHolderFn,
} from "../../../state/contractAssociateState";
import { Associate, AssociateOwner } from "../../../model/associate/associateTypes";
import { ONGOING_RESPONSE } from "../../../data/queues/QueueTypes";
import { Address as AddressInterface, BeneficialOwnerType } from "../../../model/contract/contractType";
import { associateQueue, SaveType } from "../../../data/queues/AssociateQueue";
import { Status } from "../../../data/types";
import { Language } from "../../../i18n";
import { contractAssociateState } from "../../../state/contractAssociateState";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { contractStatusState } from "../../../state/contractStatusState";
import { AddressFields, AddressRequiredFields } from "../../address/AddressFields";
import { ErrorBox } from "../../boxes/ErrorBox";
import { Contact, ContactRequiredFields } from "../../contact/Contact";
import { COPY_TYPE } from "../../copyFrom/CopyFrom";
import { HeaderWithCopy } from "../../copyFrom/HeaderWithCopy";
import { Form, FormContainer } from "../../form/Form";
import { Select } from "../../form/Select";
import { Button } from "../../interactions/Buttons/Button";
import { Name } from "../../name/Name";
import { OwnerAttributes } from "../../ownerAttributes/OwnerAttributes";
import { clearCas, getLanguageOpts } from "../../utils";
import "./UpdateAssociate.scss";
import { TrashIcon } from "../../icons/TrashIcon";
import { InfoBox } from "../../boxes/InfoBox";
import AssociateRolesList from "../AssociateRolesList";

export type AssociateRequiredFields = ContactRequiredFields & AddressRequiredFields;

export enum AssociateType {
  PRIMARY_CONTACT = "PRIMARY_CONTACT",
  ACCOUNT_HOLDER = "ACCOUNT_HOLDER",
  OWNER = "OWNER",
  SIGNEE = "SIGNEE",
  LOGIN = "LOGIN",
}

function AssociateSaveError() {
  const reload = () => window.location.reload();

  return (
    <div className="update-associate-error">
      <ErrorBox>Something went wrong. We couldn't save the information.</ErrorBox>
      <Button type="button" variant="text" onClick={reload}>
        Reload page
      </Button>
    </div>
  );
}

type AssociateValidationFields = Record<
  keyof AddressInterface | "salutation" | "firstName" | "lastName" | "email" | "phone",
  boolean
>;

const EMPTY_VALIDATION: AssociateValidationFields = {
  email: false,
  firstName: false,
  lastName: false,
  salutation: false,
  countryCode: false,
  city: false,
  postalCode: false,
  street: false,
  phone: false,
  streetNumber: false,
};

function getRequiredFields(associate: Associate, type: AssociateType): AssociateValidationFields {
  if (type === AssociateType.OWNER) {
    if (!isOwnerFn(associate)) {
      return EMPTY_VALIDATION;
    }

    return {
      email: true,
      phone: true,
      firstName: true,
      lastName: true,
      salutation: true,
      countryCode: true,
      city: true,
      postalCode: true,
      street: true,
      streetNumber: true,
    };
  }

  if (type === AssociateType.SIGNEE) {
    if (!isSigneeFn(associate)) {
      return EMPTY_VALIDATION;
    }

    return {
      email: true,
      phone: true,
      firstName: true,
      lastName: true,
      salutation: true,
      countryCode: true,
      city: true,
      postalCode: true,
      street: true,
      streetNumber: true,
    };
  }

  if (type === AssociateType.PRIMARY_CONTACT) {
    if (!isPrimaryFn(associate)) {
      return EMPTY_VALIDATION;
    }

    return {
      email: true,
      phone: true,
      firstName: true,
      lastName: true,
      salutation: true,
      countryCode: false,
      city: false,
      postalCode: false,
      street: false,
      streetNumber: false,
    };
  }

  if (type === AssociateType.ACCOUNT_HOLDER) {
    if (!isAccountHolderFn(associate)) {
      return EMPTY_VALIDATION;
    }

    return {
      email: true,
      phone: true,
      firstName: true,
      lastName: true,
      salutation: true,
      countryCode: false,
      city: false,
      postalCode: false,
      street: false,
      streetNumber: false,
    };
  }

  if (!hasLoginFn(associate)) {
    return EMPTY_VALIDATION;
  }

  return {
    email: true,
    phone: true,
    firstName: true,
    lastName: true,
    salutation: false,
    countryCode: false,
    city: false,
    postalCode: false,
    street: false,
    streetNumber: false,
  };
}

interface Props {
  associate: Associate | null;
  type: AssociateType;
  onClose: () => void;
  formContainer: React.MutableRefObject<FormContainer | undefined>;
  formName: string;
  scrollToRef?: RefObject<HTMLElement>;
  status: Status;
}

export const UpdateAssociate: React.VoidFunctionComponent<Props> = ({
  associate,
  type,
  onClose,
  formName,
  formContainer,
  scrollToRef,
  status,
}) => {
  if (!associate) {
    return null;
  }

  return (
    <UpdateAssociateInner
      onClose={onClose}
      associate={associate}
      type={type}
      formContainer={formContainer}
      formName={formName}
      scrollToRef={scrollToRef}
      status={status}
    />
  );
};

interface InnerProps extends Props {
  associate: Associate;
  status: Status;
}

const UpdateAssociateInner: React.VoidFunctionComponent<InnerProps> = ({
  associate: person,
  type,
  onClose,
  formContainer,
  formName,
  scrollToRef,
  status,
}) => {
  const { t, i18n } = useTranslation();
  const [innerStatus, setInnerStatus] = useState<Status>(Status.DEFAULT);
  const [removeStatus, setRemoveStatus] = useState<Status>(Status.DEFAULT);
  const [associate, setAssociate] = useState<Associate>(person);
  const isPrimary = isPrimaryFn(associate);
  const isOwner = isOwnerFn(associate);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const contractStatus = useRecoilValue(contractStatusState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const originalAssociate = useRef<Associate>(person);
  const [isFormInvalid, setIsFormInvalid] = useState(formContainer.current?.isInvalid);
  const handler = useRef<ReturnType<typeof setTimeout> | null>(null);
  const associateRequiredFields = useMemo(() => getRequiredFields(associate, type), [associate, type]);
  const rawAssoicate = { ...person };
  delete rawAssoicate.owner;
  const prevSaved = useRef<string>(JSON.stringify(clearCas(rawAssoicate)));
  const prevSavedOwner = useRef<string>(JSON.stringify(clearCas(person?.owner ?? {})));

  useEffect(() => {
    originalAssociate.current = { ...person };
    setAssociate(person);
    formContainer.current?.resetValidation();
  }, [person, formContainer]);

  useEffect(() => {
    const onUpdate = () => {
      if (handler && handler.current) {
        clearTimeout(handler.current);
      }

      if (isFormInvalid === formContainer.current?.isInvalid) {
        return;
      }

      if (formContainer.current?.isPending) {
        return;
      }

      handler.current = setTimeout(() => {
        setIsFormInvalid(formContainer.current?.isInvalid);
      }, 200);
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
      if (handler && handler.current) {
        clearTimeout(handler.current);
      }
    };
  }, [isFormInvalid, formContainer]);

  const callback = useCallback(
    (err, response) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      if (err) {
        setInnerStatus(Status.ERROR);
        setTimeout(() => setInnerStatus(Status.DEFAULT), 4000);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
            message: (
              <>
                Error when trying to save owner{" "}
                <b>
                  <Name associate={associate} />. Please check this owner again.
                </b>
              </>
            ),
          })
        );
        return;
      }

      setInnerStatus(Status.DEFAULT);
      if (response) {
        setAssociates(response);
      }
      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );

      onClose();
    },
    [associate, setAssociates, setDataError, setDataSaved, onClose]
  );

  const onOwnerSave = useCallback(
    (update, cb) => {
      let ownerData: Partial<AssociateOwner> = {};
      const ownerType = mainData.beneficialOwnerType;

      if (
        ownerType === BeneficialOwnerType.FIDUCIAL_OR_TRUST ||
        ownerType === BeneficialOwnerType.FOUNDATION_OR_OTHER
      ) {
        return;
      }

      // So we can't attach all properties. We have to
      // filter out those applicable
      if (
        ownerType === BeneficialOwnerType.SOLE_PROPRIETOR ||
        ownerType === BeneficialOwnerType.MULTPLE_OWNERS
      ) {
        ownerData = {
          capitalStake: update.owner?.capitalStake ?? 0,
          votingRightsStake: update.owner?.votingRightsStake ?? 0,
          cas: update.owner?.cas,
        };
      }

      // So we can't attach all properties. We have to
      // filter out those applicable
      if (
        ownerType === BeneficialOwnerType.NO_DIRECT_LISTED_COMPANY ||
        ownerType === BeneficialOwnerType.NO_DIRECT_NO_MAJORITY
      ) {
        ownerData = {
          designatedFunction: update.owner?.designatedFunction ?? "",
          designatedOwner: update.owner?.designatedOwner ?? "",
          capitalStake: 0,
          votingRightsStake: 0,
          cas: update.owner?.cas,
        };
      }

      const stringCopy = JSON.stringify(clearCas(ownerData));
      if (prevSavedOwner.current === stringCopy) {
        cb();
        return;
      }

      prevSavedOwner.current = stringCopy;
      associateQueue.saveAssociate(
        contractStatus.contractId,
        { ...update, owner: ownerData },
        SaveType.OWNER,
        cb
      );
    },
    [contractStatus.contractId, mainData]
  );

  const onSave = useCallback(
    (update) => {
      const rawAssoicate = { ...update };
      delete rawAssoicate.owner;
      const stringCopy = JSON.stringify(clearCas(rawAssoicate));

      if (type !== AssociateType.OWNER || !isOwner) {
        if (prevSaved.current === stringCopy) {
          onClose();
          return;
        }

        prevSaved.current = stringCopy;
        associateQueue.saveAssociate(contractStatus.contractId, update, SaveType.ASSOCIATE, callback);

        return;
      }

      onOwnerSave(update, (err?: any, response?: any) => {
        if (err) {
          callback(err, response);
          return;
        }

        if (prevSaved.current === stringCopy) {
          if (response) {
            setAssociates(response);
          }
          onClose();
          return;
        }

        prevSaved.current = stringCopy;
        associateQueue.saveAssociate(contractStatus.contractId, update, SaveType.ASSOCIATE, callback);
      });
    },
    [contractStatus.contractId, callback, isOwner, type, onOwnerSave, onClose, setAssociates]
  );

  const delayedClose = useCallback(() => {
    onSave(associate);
  }, [associate, onSave]);

  const discardClose = useCallback(() => {
    setAssociate(originalAssociate.current);
    onClose();
  }, [onClose]);

  const onChange = useCallback((updatedAssociate) => {
    setAssociate(updatedAssociate);
  }, []);

  const languageOpts = useMemo(() => {
    return getLanguageOpts(i18n.language as Language, contractStatus.country, t);
  }, [t, i18n.language, contractStatus.country]);

  const onLanguageChange = useCallback(
    (value) => {
      const updatedAssociate = {
        ...associate,
        language: value,
      };

      setAssociate(updatedAssociate);
    },
    [associate]
  );

  const onOwnerChange = useCallback(
    (owner: AssociateOwner) => {
      const copy = {
        ...associate,
        owner,
      };
      setAssociate(copy);
    },
    [associate]
  );

  const onRemove = useCallback(() => {
    setRemoveStatus(Status.PENDING);
    setTimeout(() => {
      associateQueue
        .deleteAssociate(contractStatus.contractId, associate.associateId)
        .then(setAssociates)
        .catch((err) => {
          setRemoveStatus(Status.ERROR);
          setTimeout(() => setRemoveStatus(Status.DEFAULT), 4000);
        });
    }, 500);
  }, [contractStatus.contractId, associate.associateId, setAssociates]);

  const onCopy = useCallback(
    (type, value) => {
      let updateAssociate;

      if (type === COPY_TYPE.ADDRESS) {
        updateAssociate = {
          ...associate,
          address: value,
        };
        setAssociate(updateAssociate);
      } else {
        updateAssociate = {
          ...associate,
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          phoneNumber: value.phoneNumber,
          saluation: value.saluation,
        };
        setAssociate(updateAssociate);
      }
    },
    [setAssociate, associate]
  );

  return (
    <div className={cx("update-associate", type)}>
      <Form
        formContainer={formContainer}
        name={formName}
        onSubmit={(event, form) => {
          if (form.isInvalid) {
            return;
          }
          delayedClose();
        }}
      >
        <div className="update-associate-content">
          <h2 className="fw-600">
            <Name associate={associate} />
          </h2>

          <AssociateRolesList associate={associate} />

          {isPrimary && type !== AssociateType.PRIMARY_CONTACT && (
            <InfoBox className="m-top-20">
              <Trans>
                This person is also set as primary contact. All changes to this person will also affect those
                values (<i>first name</i>, <i>last name</i>, <i>email</i> and <i>phone</i>)
              </Trans>
            </InfoBox>
          )}

          {innerStatus === Status.ERROR && <AssociateSaveError />}

          {isOwner && type === AssociateType.OWNER && mainData.beneficialOwnerType && (
            <>
              <div className="m-y-40">
                <h4 style={{ fontWeight: 500 }}>{t("Owner attributes")}</h4>
              </div>
              <OwnerAttributes
                status={status}
                owner={associate.owner}
                onChange={onOwnerChange}
                beneficialOwnerType={mainData.beneficialOwnerType}
                scrollToRef={scrollToRef}
              />
              <hr />
            </>
          )}
          <div className="update-associate-contact">
            <div className="m-y-40">
              <HeaderWithCopy
                header={t("Contact details")}
                headerType="h4"
                onChange={onCopy}
                type={COPY_TYPE.ASSOCIATE}
                associate={associate}
              />
            </div>

            <div className="triple-auto-columns">
              <Contact
                disabled={status === Status.DISABLED}
                onChange={onChange}
                associate={associate}
                contactRequiredFields={associateRequiredFields}
                scrollToRef={scrollToRef}
              />

              <div>
                <Select
                  disabled={status === Status.DISABLED}
                  onChange={onLanguageChange}
                  label={t("Preferred language")}
                  alternatives={languageOpts}
                  value={associate.language}
                />
              </div>
            </div>
          </div>

          <div className="update-associate-address">
            <hr />
            <div className="m-y-40">
              <HeaderWithCopy
                header={t("Address")}
                headerType="h4"
                onChange={onCopy}
                type={COPY_TYPE.ADDRESS}
                associate={associate}
              />
            </div>

            <div className="triple-auto-columns">
              <AddressFields
                disabled={status === Status.DISABLED}
                onChange={onChange}
                associate={associate}
                addressRequiredFields={associateRequiredFields}
                scrollToRef={scrollToRef}
              />
              <div />
            </div>
          </div>
        </div>

        {status !== Status.DISABLED && (
          <div className="update-associate-bottom-actions">
            {removeStatus === Status.ERROR && (
              <div className="m-bottom-10 update-associate-error-box">
                <ErrorBox relative>{t("Failed to remove person")}</ErrorBox>
              </div>
            )}

            <div className="save-associate-action-container">
              <Button className="danger-button" onClick={onRemove} status={removeStatus}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 8,
                  }}
                >
                  <TrashIcon />
                </div>
                {t("Remove person")}
              </Button>
              <Button
                type="button"
                className="cancel-button"
                variant="outlined"
                color="secondary"
                onClick={discardClose}
              >
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                className="save-button"
                status={isFormInvalid ? Status.DISABLED : Status.DEFAULT}
              >
                {t("Save changes")}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
