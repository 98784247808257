import { atom, selector } from "recoil";

export enum LumaFieldName {
  IntendedUseOfProducts = "What is the Intended use of products?",
  MaximumTransactionValue = "Maximum Transaction Value",
  CompanyEmailAddress = "Company email address",
  AddressLine1 = "Address line 1",
  TotalEstimatedAnnualTurnoverWorldline = "What is the total estimated annual turnover that will be processed by Worldline?",
  AccountHolderName = "Account holder name",
  CompanyPhoneNumber = "Company phone number",
  DateOfIncorporation = "Date of Incorporation",
  EstimatedAnnualTurnoverSalesLocation = "What is the estimated annual turnover that will be processed by the sales location?",
  ApplicableBusinessPractices = "Select the applicable business practices",
  IBAN = "IBAN",
  LegalForm = "Legal form",
  ProcessingURL = "Processing URL",
  SettlementCurrency = "Settlement currency",
  WLProductType = "Select the type of WL product that the company will utilize",
  PostalCode = "Postal code",
  CustomerOrParentSelection = "Please select if the customer or its parent is",
  SwiftBIC = "Swift/BIC",
  ProcessingCurrency = "Processing currency",
  BankName = "Bank name",
  ProductServiceSoldViaWL = "What product / service will be sold via the WL product?",
  HasVATNumber = "Does the Company have a VAT Number?",
  SupervisedByAuthority = "Is the company supervised by a regulatory authority or any other licensing body?",
  BusinessModel = "Business model",
  MainCountriesOfOperation = "Select the main countrie(s) in which the company operates?",
  AccountNumber = "Account number",
  Country = "Country",
  City = "City",
  RelationshipPurpose = "What is the purpose of the relationship?",
  BankAccountCountry = "Bank/BIC account country",
  RemittedToThirdParty = "Are funds remitted to a third party?",
  EstimatedTransactionCountPerYear = "What is the estimated number of transactions per year?",
  MerchantCategoryCodes = "Merchant Category Code(s) (MCC)",
  AverageTransactionValue = "Average Transaction Value",

  MainBusinessActivity = "The main business activity the company conduct", // we dont actually get this back from Luma, but put it here for code convenience
}

export interface LumaRequirementResponse {
  name: LumaFieldName;
  propertyTypeId: string | undefined;
}

export const lumaRequirementsState = atom<{ error: boolean; requirements: LumaRequirementResponse[] }>({
  key: "luma-requirements-state",
  default: {
    error: false,
    requirements: [],
  },
});

export const lumaFieldnameSelector = selector<LumaFieldName[]>({
  key: "luma-field-names-selector",
  get: ({ get }) => {
    const { requirements } = get(lumaRequirementsState);
    return requirements.map((luma) => luma.name);
  },
});

interface LumaFieldsWithPropertyId {
  customerOrParentId: string;
  applicableBusinessId: string;
  intededUseOfProductId: string;
  purposeOfRelationshipId: string;
  mainBusinessActivity: string;
}

// fallbacks
const CUSTOMER_OR_PARENT_IS_PROPERTY_TYPE_ID = "6ac1ae26-5669-4ff7-bbdb-16333aae1429";
const APPLICABLE_BUSINESS_PRACTICES_PROPERTY_TYPE_ID = "3d2b40ab-7804-4124-9334-ac2c3136e9c2";
const PURPOSE_OF_RELATIONSHIP_PROPERTY_TYPE_ID = "6ad6d71e-7cc4-477e-ba08-73507eb7c3d0";
const INTENDED_USE_OF_PRODUCT_PROPERTY_TYPE_ID = "714653e7-c7e6-49ba-9074-83cf017227f8";
const MAIN_BUSINESS_ACTIVITY_PROPERTY_TYPE_ID = "0f31daee-fb53-4d03-9607-28a3bf153406";

export const lumaPropertyIdFieldsSelector = selector<LumaFieldsWithPropertyId>({
  key: "luma-property-type-selector",
  get: ({ get }) => {
    const { requirements } = get(lumaRequirementsState);

    const customerOrParentId =
      requirements.find((field) => field.name === LumaFieldName.CustomerOrParentSelection)?.propertyTypeId ??
      CUSTOMER_OR_PARENT_IS_PROPERTY_TYPE_ID;

    const purposeOfRelationshipId =
      requirements.find((field) => field.name === LumaFieldName.RelationshipPurpose)?.propertyTypeId ??
      PURPOSE_OF_RELATIONSHIP_PROPERTY_TYPE_ID;

    const intededUseOfProductId =
      requirements.find((field) => field.name === LumaFieldName.IntendedUseOfProducts)?.propertyTypeId ??
      INTENDED_USE_OF_PRODUCT_PROPERTY_TYPE_ID;

    const mainBusinessActivity = MAIN_BUSINESS_ACTIVITY_PROPERTY_TYPE_ID; // currently not available from Luma...

    const applicableBusinessId =
      requirements.find((field) => field.name === LumaFieldName.ApplicableBusinessPractices)
        ?.propertyTypeId ?? APPLICABLE_BUSINESS_PRACTICES_PROPERTY_TYPE_ID;

    const fields: LumaFieldsWithPropertyId = {
      customerOrParentId,
      applicableBusinessId,
      intededUseOfProductId,
      purposeOfRelationshipId,
      mainBusinessActivity,
    };

    return fields;
  },
});
