import React, { ChangeEvent, FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Status } from "../../data/types";
import { Alternative } from "../interactions/InputTypes";
import cx from "classnames";
import { Checkmark } from "../icons/Checkmark";
import { Disabled } from "../icons/Disabled";
import { Expand } from "../icons/Expand";
import { Pending } from "../icons/Pending";
import { id } from "../utils";
import { Error } from "../icons/Error";
import { AnimatePresence, motion } from "framer-motion";
import { Checkbox } from "../icons/Checkbox";
import styles from "./MultiSelect.module.scss";

import { IoMdCloseCircle } from "react-icons/io";

export interface Props {
  className?: string;
  values?: string[];
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  name?: string;
  message?: string;
  onChange: (values: string[], name: string, value: string) => void;
  onBlur?: (values: string[] | undefined, name: string, value: string) => void;
  alternatives: Alternative<string>[];
  disabled?: boolean;
  placeholder?: string;
  scrollToRef?: RefObject<HTMLElement>;
  status: Status;
  required: boolean;
}
export function BaseMultiSelect({
  className,
  name,
  label = null,
  onChange,
  onBlur,
  hint = null,
  message = undefined,
  placeholder = "Select mutiple",
  alternatives = [],
  values = [],
  disabled = false,
  status,
  required,
}: Props) {
  const identifier = useRef<string>(id());

  const [open, setOpen] = useState(false);

  const selectedOptions = alternatives.filter((alt) => values?.includes(alt.value));
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const internalChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      const isSelected = values?.includes(value);
      const updatedValues = isSelected ? values?.filter((v) => v !== value) : [...values, value];

      onChange(updatedValues, identifier.current, value);
    },
    [onChange, values]
  );

  const onSelectedClick = (value: string) => {
    const updatedValues = values?.filter((v) => v !== value);
    onChange(updatedValues, identifier.current, value);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const generateMessageOrHint = () => {
    if (message) return <div className="input-message">{message}</div>;
    if (hint) return <div className="input-hint">{hint}</div>;
    return null;
  };

  return (
    <div className={styles.root} ref={dropdownRef}>
      <label className={cx("input", "input-dropdown", className, status)} htmlFor={identifier.current}>
        <div className="input-label-wrapper">
          <div className="input-label">
            <div className="input-label-tag">{label || <div className="invisible">empty</div>}</div>
          </div>
          {values.length < 1 && required && <div className="required-marker fs-small"></div>}
        </div>

        <div className="input-frame">
          <input
            name={name || identifier.current}
            id={identifier.current}
            placeholder={placeholder}
            disabled={disabled || status === Status.DISABLED || status === Status.PENDING}
            value={values.length > 0 ? `${values.length} options selected` : "Select multiple"}
            style={{ textAlign: "left" }}
            type="button"
            onClick={() => setOpen(!open)}
          />

          <div className="input-status">
            <Expand />
            <Checkmark />
            <Disabled />
            <Pending />
            <Error />
          </div>

          <AnimatePresence>
            {open && (
              <motion.ul
                key="available-options"
                className={styles.options}
                initial={{ opacity: 0.5, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                {alternatives.map((alt, idx) => {
                  const currentValue = alt.value;
                  const isChecked = !!values?.find((value) => value === currentValue);
                  const isDisabled = alt.disabled || status === Status.DISABLED;

                  return (
                    <Option
                      key={`select-option-${alt.value}`}
                      disabled={isDisabled}
                      value={alt.value as string}
                      text={alt.text as string}
                      isChecked={isChecked}
                      labelId={idx + "s"}
                      onChange={internalChange}
                    />
                  );
                })}
              </motion.ul>
            )}
          </AnimatePresence>
        </div>

        {(hint || message) && <div className="input-messages">{generateMessageOrHint()}</div>}
      </label>
      <ul className={styles.selectedOptions}>
        <AnimatePresence>
          {selectedOptions.map((alt) => {
            return (
              <SelectedOption
                key={`selected-option-${alt.value}`}
                onSelectedClick={() => onSelectedClick(alt.value as string)}
                alternative={alt}
                disabled={status === Status.DISABLED || disabled}
              />
            );
          })}
        </AnimatePresence>
      </ul>
    </div>
  );
}

interface OptionProps {
  labelId: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  value: string;
  disabled: boolean;
  text: string;
}
const Option: FC<OptionProps> = ({ labelId, onChange, isChecked, value, disabled, text }) => {
  return (
    <div className={cx(styles.checkbox, { [styles.disabled]: disabled })}>
      <input
        name={labelId}
        type="checkbox"
        onChange={onChange}
        value={value}
        checked={isChecked}
        disabled={disabled}
      />

      <Checkbox checked={isChecked} className={styles.indicator} />

      <label htmlFor={labelId}>{text}</label>
    </div>
  );
};

interface SelectedOptionProps {
  alternative: Alternative<string>;
  onSelectedClick: (val: string) => void;
  disabled: boolean;
}

const SelectedOption: FC<SelectedOptionProps> = ({ alternative, onSelectedClick, disabled }) => {
  return (
    <motion.li
      key={`selected-option-${alternative.value}`}
      className={styles.selectedValue}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ type: "spring", stiffness: 250, damping: 30 }}
      role="button"
      onClick={disabled ? () => null : () => onSelectedClick(alternative.value)}
    >
      <span className={styles.selectedText}>{alternative.text}</span>
      {!disabled && <IoMdCloseCircle />}
    </motion.li>
  );
};
