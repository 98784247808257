import { useState, useCallback, useEffect } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import {
  CreateContractError,
  CreateContractResponse,
  CreateContractSalesForceError,
  dataContracts,
} from "../../data/dataContracts";
import { Status } from "../../data/types";
import { CorrelationId } from "../../model/common/commonType";
import { Validity } from "../../model/contract/contractType";
import { CONTRACT_ROUTE } from "../Contract/ContractPage";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { Beacon } from "../../components/beacon/Beacon";
import { InfoBox } from "../../components/boxes/InfoBox";
import { Pending } from "../../components/icons/Pending";
import { Button } from "../../components/interactions/Buttons/Button";
import { T } from "../../components/translation/T";

import styles from "./CreateContractWithCorrelationID.module.scss";
import { getRandomInt } from "../../components/utils";
import { SfLink } from "../../components/links/SFlink";
import useEnvironment from "../../hooks/useEnvironment";

interface Props {
  correlationId: CorrelationId;
}

export const CreateContractWithCorrelationID: React.FunctionComponent<Props> = ({ correlationId }) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [serverError, setServerError] = useState<CreateContractSalesForceError | CreateContractError | null>(
    null
  );
  const [createResponse, setCreateResponse] = useState<CreateContractResponse | null>(null);
  const [successUrl, setSuccesUrl] = useState<string>();

  const { isProduction } = useEnvironment();

  const load = useCallback(async () => {
    if (!correlationId) return;

    try {
      const response = await dataContracts.newContractWithCorrelationId(correlationId);
      setCreateResponse(response);

      await delay(getRandomInt(500, 2000));
      setStatus(Status.SUCCESS);
      setSuccesUrl(generatePath(CONTRACT_ROUTE, { id: response.contractId }));
    } catch (err) {
      await delay(getRandomInt(400, 2000));

      if (!err || !(err as any).data) {
        return setStatus(Status.ERROR);
      }

      const { error, message } = (err as any).data;

      setStatus(Status.ERROR);
      setServerError(error || message);
    }
  }, [correlationId]);

  useEffect(() => {
    setTimeout(load, 500);
  }, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    load();
  }, [load]);

  const renderIndicator = useCallback((status: Status, result?: boolean) => {
    if (status === Status.ERROR) return <Beacon mini validity={Validity.MISSING} />;
    if (status === Status.PENDING) return <Pending />;
    return result ? <Beacon mini validity={Validity.VALID} /> : <Beacon mini validity={Validity.PARTIAL} />;
  }, []);

  const renderError = () => {
    if (status !== Status.ERROR || !serverError) return;

    if (isSalesForceError(serverError)) {
      return (
        <div className={styles.error}>
          <h5 className="fw-700">
            <T>Ooh noo!</T> 😔
          </h5>
          <div className="m-bottom-30 fw-500">
            <T>{getErrorMessage(serverError as CreateContractSalesForceError)}</T>
          </div>
          <SfLink>
            <T>Back to Salesforce</T>
          </SfLink>
        </div>
      );
    }

    return (
      <>
        <div className={styles.error}>
          <h5 className="fw-700">
            <T>Ooh noo!</T> 😔
          </h5>

          <div className="fw-500">
            <T>Something unexpected went wrong.</T>
          </div>
        </div>
        <div className="m-top-20">
          <Button block onClick={retry}>
            <T>Retry</T>
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.list}>
      <h1>
        <T>Creating contract</T>
      </h1>

      {!isProduction && (
        <div className="m-top-40">
          <InfoBox relative>
            <T>We're in the test environment. Feel free to break things</T>
            <br />
            <span className="text-large">🔨🪓🧨💣</span>
          </InfoBox>
        </div>
      )}

      <div className={styles.data_list}>
        <dl className="fw-500 ">
          <dt>
            <T>Registration number</T>
          </dt>
          <dd>{createResponse?.companyRegistrationId}</dd>
          <dt>
            <T>Company name</T>
          </dt>
          <dd>{createResponse?.companyName}</dd>
          <dt>
            <T>Registry lookup</T>
          </dt>
          <dd>{renderIndicator(status, createResponse?.registryLookupFound)}</dd>
          <dt>
            <T>VAT lookup</T>
          </dt>
          <dd>{renderIndicator(status, createResponse?.vatLookupFound)}</dd>
          <>
            <dt>
              <T>Bank account lookup</T>
            </dt>
            <dd>{renderIndicator(status, createResponse?.bankAccountsAvailable)}</dd>
          </>
        </dl>
      </div>

      <div className="m-top-40">
        <AnimateHeight name={status + serverError}>
          <div>
            {renderError()}

            {successUrl && status === Status.SUCCESS && (
              <div className={styles.success}>
                <div className="center">
                  <h5 className="fw-700">
                    🤩
                    <span style={{ margin: "0 10px" }}>
                      <T>Success!</T>
                    </span>
                    🤩
                  </h5>
                </div>
                <div className="center fw-500">
                  <div className="m-bottom-10">
                    <T>Contract was created.</T>
                  </div>

                  <Link className="m-top-10 as-button" to={successUrl}>
                    <T>Get me there</T>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

function getErrorMessage(error: CreateContractSalesForceError): string {
  switch (error) {
    case CreateContractSalesForceError.CONTRACT_ALREADY_STARTED:
      return "This contract has already been created. Nothing to do here.";

    case CreateContractSalesForceError.NO_CASE_FOUND:
      return "We couldn't find a valid case file. Unfortunately, there is nothing we can do at this moment. Please check so that this is a valid contract in Salesforce.";

    case CreateContractSalesForceError.PRIMARY_CONTACT_NOT_VALID:
      return "The primary contact associated with this contract is not valid. Unfortunately, there is nothing we can do at this moment. Please update this contract in Salesforce so that it has a valid primary contact.";

    case CreateContractSalesForceError.COUNTRY_NOT_VALID:
      return "This contract is associated with a country that we don't yet support. Unfortunately, there is nothing we can do at this moment.";

    case CreateContractSalesForceError.ORGANIZATION_NUMBER_NOT_VALID:
      return "This contract has an identification number that is not valid. Unfortunately, there is nothing we can do.";

    case CreateContractSalesForceError.LEGAL_ENTITY_TYPE_NOT_ALLOWED:
      return "This company has a legal entity type that we don't yet support.";

    case CreateContractSalesForceError.COMPANY_NOT_ACTIVE:
      return "This company is not active. Unfortunately, there is nothing we can do.";

    case CreateContractSalesForceError.EMAIL_MISSING:
      return "The primary contact associated with this contract is missing an email.";

    case CreateContractSalesForceError.CASE_COUNTRY_NOT_SAME_AS_WLX_USER:
      return "Your WLx user is associated with a different country than the one provided from the Salesforce case";

    case CreateContractSalesForceError.CASE_EMAIL_NOT_THE_SAME_AS_WLX_USER:
      return "Your WLx user has a different email address than the one provided from the Salesforce case";

    case CreateContractSalesForceError.COMPANY_ID_ALREADY_IN_USE:
      return "The company registration ID provided from Salesforce is already in use in WLx";

    default:
      return error || "Unknown error. My, oh my. Contact your local developer.";
  }
}

async function delay(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

const isSalesForceError = (serverError: CreateContractSalesForceError | CreateContractError) => {
  return (
    serverError &&
    Object.values(CreateContractSalesForceError).includes(serverError as CreateContractSalesForceError)
  );
};
