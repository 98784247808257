import { ECommerceType, Store2 } from "./contractType";
import { ContractType } from "./contractType";
import { BeneficialOwnerType, DocumentType } from "./contractType";

// <---------- DocumentType ---------->

export function documentTypeDescription(type: DocumentType) {
  switch (type) {
    case DocumentType.ID_DOCUMENT:
      return "Copy of a valid personal document, a passport or national identification card";
    case DocumentType.BANK_STATEMENT:
      return "Confirmation of bank relation or recent account statement";
    case DocumentType.BUSINESS_CERTIFICATE:
      return "Excerpt from a national official business registry showing signatory power and company status";
    case DocumentType.TAXI_LICENSE:
      return "Proof of accreditation/taxi license";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Copy of signed document showing the power to stand in for a representative of the company";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Excerpt or copy of relevant accreditation documentation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "The latest audited financial statement";
    case DocumentType.COMPANY_FORMATION_ESTABLISHMENT_DOCUMENT:
      return "Company formation Establishment Document";
    case DocumentType.MERCHANT_APPLICATION_FORM:
      return "Merchant Application Form";
    case DocumentType.REGULATORY_COMPLIANCE_DOCUMENT:
      return "Regulatory Compliance Document";
    case DocumentType.PROOF_OF_OWNERSHIP_AND_CONTROL:
      return "Proof of Ownership and Control";
    case DocumentType.PROOF_OF_BANK_ACCOUNT_DETAILS:
      return "Proof of Bankaccountdetails";
    case DocumentType.LEGAL_AUTHORIZATION_DOCUMENT:
      return "Legal Authorization Document";
    case DocumentType.OTHER_DOCUMENT:
      return "Other Document";
    case DocumentType.PROOF_OF_COUNTRY_OF_RESIDENCY:
      return "Proof of Country of Residency";
    case DocumentType.FINANCIAL_INFORMATION_DOCUMENT:
      return "Financial Information Document";
  }
  return "(Description missing for " + type.toString() + ")";
}

export function documentTypeDisplay(type: DocumentType) {
  switch (type) {
    case DocumentType.ID_DOCUMENT:
      return "Passport / National ID card";
    case DocumentType.BANK_STATEMENT:
      return "Bank statement";
    case DocumentType.BUSINESS_CERTIFICATE:
      return "Business certificate";
    case DocumentType.TAXI_LICENSE:
      return "Taxi License";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Power of attorney";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Proof of accreditation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "Finacial statment";
    case DocumentType.SALES_CUSTOM_REQUEST:
      return "Document request";
    case DocumentType.E_COMMERCE_SUPPLEMENT:
      return "E-commerce supplement";
    case DocumentType.COMPANY_FORMATION_ESTABLISHMENT_DOCUMENT:
      return "Company formation Establishment Document";
    case DocumentType.MERCHANT_APPLICATION_FORM:
      return "Merchant Application Form";
    case DocumentType.REGULATORY_COMPLIANCE_DOCUMENT:
      return "Regulatory Compliance Document";
    case DocumentType.PROOF_OF_OWNERSHIP_AND_CONTROL:
      return "Proof of Ownership and Control";
    case DocumentType.PROOF_OF_BANK_ACCOUNT_DETAILS:
      return "Proof of Bankaccountdetails";
    case DocumentType.LEGAL_AUTHORIZATION_DOCUMENT:
      return "Legal Authorization Document";
    case DocumentType.OTHER_DOCUMENT:
      return "Other Document";
    case DocumentType.PROOF_OF_COUNTRY_OF_RESIDENCY:
      return "Proof of Country of Residency";
    case DocumentType.FINANCIAL_INFORMATION_DOCUMENT:
      return "Financial Information Document";
  }
}

// <---------- BeneficialOwnerType ---------->

export function beneficialOwnerTypeDisplay(type: BeneficialOwnerType) {
  switch (type) {
    case BeneficialOwnerType.SOLE_PROPRIETOR:
      return "Sole proprietorship";
    case BeneficialOwnerType.MULTPLE_OWNERS:
      return "One or more beneficial owners";
    case BeneficialOwnerType.NO_DIRECT_NO_MAJORITY:
      return "No direct or indirect beneficial owners";
    case BeneficialOwnerType.NO_DIRECT_LISTED_COMPANY:
      return "Listed on a stock exchange";
    case BeneficialOwnerType.FIDUCIAL_OR_TRUST:
      return "Fiducies or trusts";
    case BeneficialOwnerType.FOUNDATION_OR_OTHER:
      return "Foundations, and legal arrangements similar to trusts";
  }
  // return "(Description missing for " + type.toString() + ")";
}

export function beneficialOwnerTypeDescription(type?: BeneficialOwnerType) {
  if (!type) {
    return "";
  }
  switch (type) {
    case BeneficialOwnerType.SOLE_PROPRIETOR:
      return "The company is a sole proprietorship, and the sole proprietor is the only beneficial owner.";
    case BeneficialOwnerType.MULTPLE_OWNERS:
      return (
        "The company is not a sole proprietorship. There are one or more beneficial owners directly or indirectly holding/controlling a " +
        "stake of more than 25% in the company or controlling the company by other means."
      );
    case BeneficialOwnerType.NO_DIRECT_NO_MAJORITY:
      return (
        "If nobody directly or indirectly holds a stake of 25% plus one share in the company or controls the company by other means, " +
        "the natural person who holds the position of senior manager (e.g. chief executive officer, member of the board of directors, etc.) " +
        "has to complete the beneficial owner section."
      );
    case BeneficialOwnerType.NO_DIRECT_LISTED_COMPANY:
      return "The company is listed on the stock exchange.";
    case BeneficialOwnerType.FIDUCIAL_OR_TRUST:
      return "For trusts and fiduciary arrangements, it is now required that all participants must be identified";
    case BeneficialOwnerType.FOUNDATION_OR_OTHER:
      return (
        "In the case of legal entities such as foundations, and legal arrangements similar to trusts, any natural person holding equivalent " +
        "or similar positions to those referred to in point 4 needs to complete the beneficial owner section. Prior to submission, all forms need " +
        "to be signed and accompanied with the supporting documents required."
      );
  }
  // return "(Description missing for " + type.toString() + ")";
}

// <---------- Contract ---------->

const CONTRACT_TYPE_STRING_MAP: Record<ContractType, string> = {
  [ContractType.INSTORE]: "Instore",
  [ContractType.ECOMMERCE]: "E-commerce",
};

export const getContractTypeDisplayString = (type: ContractType) => {
  const typeString = CONTRACT_TYPE_STRING_MAP[type];
  return typeString ?? type;
};

export const getDisplayStringFromContractTypes = (types: ContractType[]) => {
  const isEcom = types.includes(ContractType.ECOMMERCE);
  const isInstore = types.includes(ContractType.INSTORE);

  if (isEcom && isInstore) return "Omni channel";

  if (isEcom) return getContractTypeDisplayString(ContractType.ECOMMERCE);

  return getContractTypeDisplayString(ContractType.INSTORE);
};

// <---------- Store ---------->

const ECOMMERCE_TYPE_STRING_MAP: Record<ECommerceType, string> = {
  [ECommerceType.WEBSITE]: "Website",
  [ECommerceType.TEST_WEBSITE]: "Test Website",
  [ECommerceType.PAYMENT_LINKS]: "Payment links",
};

export const getEcomTypeDisplayString = (type: ECommerceType) => {
  const typeString = ECOMMERCE_TYPE_STRING_MAP[type];
  return typeString ?? type;
};

export const getStoreAddress = (store: Store2) => {
  const { address } = store;
  const addressIsComplete = address.street && address.streetNumber && address.city;

  return addressIsComplete ? `${address.street} ${address.streetNumber}, ${address.city}` : "";
};
