import { FC, RefObject, useRef } from "react";

import { BaseMultiSelect, Props as BaseMultiSelectProps } from "./MultiSelectBase";
import { Status } from "../../data/types";
import { useForm } from "./hooks/useForm";
import { ValidationProps, useValidation } from "./hooks/useValidation";
import { RequiredValidatorName } from "./validators/RequiredValidator";

type BaseProps = Omit<BaseMultiSelectProps, "status" | "required">;

interface Props extends ValidationProps, BaseProps {
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
}

export const MultiSelect: FC<Props> = ({
  values,
  validators = [],
  forceValidation = false,
  disabled = false,
  scrollToRef,
  ...props
}) => {
  const ref = useRef<HTMLSelectElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useRef("select_" + Math.random().toString(36).substring(2, 9));
  const [validity, errorMessages, resetValidation, status, hintMessages] = useValidation<typeof values>(
    values,
    innerValidators,
    forceValidation
  );
  useForm(inputId.current, validity, values, resetValidation, ref ?? scrollToRef);

  const isRequired = innerValidators.some((validator) => validator.name === RequiredValidatorName);
  const message = errorMessages.length > 0 ? errorMessages[0] : undefined;
  const hint = hintMessages.length > 0 ? hintMessages[0] : undefined;

  return (
    <BaseMultiSelect
      status={disabled ? Status.DISABLED : status}
      values={values}
      disabled={disabled || status === Status.DISABLED}
      required={isRequired}
      message={message}
      hint={hint}
      {...props}
    />
  );
};
