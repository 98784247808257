import { FC, useEffect, useMemo, useState } from "react";
import { Select } from "../../../components/form/Select";
import { useRecoilState, useRecoilValue } from "recoil";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { NumberValidator } from "../../../components/form/validators/NumberValidator";
import { MainContractData } from "../../../model/contract/contractType";
import { MultiSelect } from "../../../components/form/MultiSelect";
import { Alternative } from "../../../components/interactions/InputTypes";
import { dataContracts } from "../../../data/dataContracts";
import { ContractId } from "../../../model/common/commonType";
import { useDebounceFn } from "../../../hooks/useDebounce";
import { RadioGroup } from "../../../components/form/RadioGroup";
import {
  LumaFieldName,
  lumaFieldnameSelector,
  lumaPropertyIdFieldsSelector,
  lumaRequirementsState,
} from "../../../state/lumaRequirementsState";
import { ErrorBox } from "../../../components/boxes/ErrorBox";

interface Props {
  onSave: (copy: any) => void;
  disabled: boolean;
  contractId: ContractId;
}

const SERVER_ERROR_MESSAGE = "Could not fetch options from the server. Please try again.";

interface DynamicLumaFields {
  customerOrParentIsAlts: Alternative<string>[];
  purposeOfRelationShipAlts: Alternative<string>[];
  intendedUseOfProductAlts: Alternative<string>[];
  mainBusinessActivityAlts: Alternative<string>[];
  applicableBusinessPracticesAlts: Alternative<string>[];
}

const CompanyDataLumaInputs: FC<Props> = ({ onSave, disabled, contractId }) => {
  const [mainData, setMainData] = useRecoilState(contractMainContractDataState);
  const { t } = useTranslation();
  const [serverAlternatives, setServerAlternatives] = useState<DynamicLumaFields>({
    customerOrParentIsAlts: [],
    purposeOfRelationShipAlts: [],
    intendedUseOfProductAlts: [],
    mainBusinessActivityAlts: [],
    applicableBusinessPracticesAlts: [],
  });
  const [fetchError, setFetchError] = useState(false);

  const lumaPropertyFields = useRecoilValue(lumaPropertyIdFieldsSelector);
  const lumaFieldnames = useRecoilValue(lumaFieldnameSelector);
  const { error } = useRecoilValue(lumaRequirementsState);

  useEffect(() => {
    const fetchBusinessActivity = async () => {
      setFetchError(false);

      Promise.all([
        dataContracts.lumaLookupQuery(contractId, lumaPropertyFields.customerOrParentId),
        dataContracts.lumaLookupQuery(contractId, lumaPropertyFields.purposeOfRelationshipId),
        dataContracts.lumaLookupQuery(contractId, lumaPropertyFields.intededUseOfProductId),
        dataContracts.lumaLookupQuery(contractId, lumaPropertyFields.mainBusinessActivity),
        dataContracts.lumaLookupQuery(contractId, lumaPropertyFields.applicableBusinessId),
      ])
        .then(
          ([
            customerOrParent,
            purposeOfRelationship,
            intendedUseOfProducts,
            mainBusinessActivity,
            applicableBusinessPractices,
          ]) => {
            const intededUseOfProductRegex = /in-store|POS/i;

            const serverAlternatives = {
              customerOrParentIsAlts: mapValuesToAlternatives(customerOrParent.values),
              purposeOfRelationShipAlts: mapValuesToAlternatives(purposeOfRelationship.values),
              intendedUseOfProductAlts: mapValuesToAlternatives(
                intendedUseOfProducts.values.filter((value) => intededUseOfProductRegex.test(value)) // for now remove other alternatives (e-com, moto) as they are not relevant for red...
              ),
              mainBusinessActivityAlts: mapValuesToAlternatives(mainBusinessActivity.values),
              applicableBusinessPracticesAlts: mapValuesToAlternatives(applicableBusinessPractices.values),
            };

            setServerAlternatives(serverAlternatives);
          }
        )
        .catch((err) => {
          setFetchError(true);
          console.log(err);
        });
    };

    fetchBusinessActivity();
  }, [contractId, lumaPropertyFields]);

  const binaryChoiceAlts: Alternative<string>[] = useMemo(
    () => [
      {
        value: "Yes",
        text: t("Yes"),
      },
      {
        value: "No",
        text: t("No"),
      },
    ],
    [t]
  );

  const onUpdateMainData = <Key extends keyof MainContractData>(key: Key, value: MainContractData[Key]) => {
    const copy = { ...mainData, [key]: value };
    onSave(copy);
  };

  const debounceUpdate = useDebounceFn(onUpdateMainData, 500);

  if (error) {
    return (
      <ErrorBox className="m-top-20">
        {t("Could not fetch fields data for Luma. Please reload the page and try again.")}
      </ErrorBox>
    );
  }

  return (
    <div className="double-auto-columns m-top-40">
      {lumaFieldnames.includes(LumaFieldName.CustomerOrParentSelection) && (
        <div>
          <Select
            name="Customer or parent is"
            onChange={
              fetchError ? () => null : (value) => onUpdateMainData("selectIfCompanyOrParentIs", value)
            }
            disabled={fetchError || disabled}
            alternatives={serverAlternatives.customerOrParentIsAlts}
            value={mainData.selectIfCompanyOrParentIs}
            label={t("Please select if the customer or its parent is")}
            validators={[new RequiredValidator(t("Customer or parent is required"))]}
          />
          {fetchError && (
            <div className="color-red-heavy fs-small m-top-10 fw-500">{t(SERVER_ERROR_MESSAGE)}</div>
          )}
        </div>
      )}

      {lumaFieldnames.includes(LumaFieldName.DateOfIncorporation) && (
        <TextInput
          type="date"
          onChange={(value) => onUpdateMainData("dateOfIncorporation", value)}
          name="dateOfIncorporation"
          label={t("Date of Incorporation")}
          disabled={disabled}
          placeholder="yyyy-mm-dd"
          value={mainData.dateOfIncorporation}
          validators={[new RequiredValidator(t("Date of incorporation is required"))]}
        />
      )}

      {lumaFieldnames.includes(LumaFieldName.SupervisedByAuthority) && (
        <RadioGroup
          onChange={(value) => onUpdateMainData("supervisedByRegulatoryAuth", value)}
          disabled={disabled}
          alternatives={binaryChoiceAlts}
          value={mainData.supervisedByRegulatoryAuth}
          label={t("Is the company supervised by a regulatory authority or any other licensing body")}
          validators={[new RequiredValidator(t("Is the company supervised is required"))]}
        />
      )}

      {lumaFieldnames.includes(LumaFieldName.RemittedToThirdParty) && (
        <RadioGroup
          onChange={(value) => onUpdateMainData("areFundsRemittedToThirdParty", value)}
          disabled={disabled}
          alternatives={binaryChoiceAlts}
          value={mainData.areFundsRemittedToThirdParty}
          label={t("Are funds remitted to a third party")}
          validators={[new RequiredValidator(t("Funds remitted to a third party is required"))]}
        />
      )}

      {lumaFieldnames.includes(LumaFieldName.BusinessModel) && (
        <TextInput
          onChange={(value) => {
            const copy = { ...mainData, businessModel: value };
            setMainData(copy);
            debounceUpdate("businessModel", value);
          }}
          name="businessModel"
          label={t("Business model")}
          value={mainData.businessModel}
          validators={[
            new RequiredValidator(t("Business model is required")),
            new MaxLengthValidator(
              50,
              t("Business model cannot be longer than {{max}} characters", {
                max: 50,
              })
            ),
          ]}
          disabled={disabled}
        />
      )}

      {lumaFieldnames.includes(LumaFieldName.TotalEstimatedAnnualTurnoverWorldline) && (
        <TextInput
          onChange={(value) => {
            const copy = { ...mainData, totalEstimateAnnualTurnover: value };
            setMainData(copy);
            debounceUpdate("totalEstimateAnnualTurnover", value);
          }}
          name="totalEstimateAnnualTurnover"
          label={t("Total estimated annual turnover")}
          value={mainData.totalEstimateAnnualTurnover}
          validators={[
            new RequiredValidator(t("Total estimated annual turnover is required")),
            new NumberValidator(t("Total estimated annual turnover must be a number")),
            new MaxLengthValidator(
              50,
              t("Total estimated annual turnover cannot be longer than {{min}} characters", {
                min: 50,
              })
            ),
          ]}
          disabled={disabled}
        />
      )}

      {lumaFieldnames.includes(LumaFieldName.RelationshipPurpose) && (
        <div>
          <MultiSelect
            values={mainData.purposeOfRelationship}
            alternatives={serverAlternatives.purposeOfRelationShipAlts}
            label={t("What is the purpose of the relationship?")}
            onChange={(value) => onUpdateMainData("purposeOfRelationship", value)}
            validators={[new RequiredValidator("Purpose of relationship is required")]}
            disabled={fetchError || disabled}
          />
          {fetchError && (
            <div className="color-red-heavy fs-small m-top-10 fw-500">{t(SERVER_ERROR_MESSAGE)}</div>
          )}
        </div>
      )}

      {lumaFieldnames.includes(LumaFieldName.ApplicableBusinessPractices) && (
        <div>
          <MultiSelect
            values={mainData.applicableBusinessPractices}
            alternatives={serverAlternatives.applicableBusinessPracticesAlts}
            label={t("Select the applicable business practice")}
            onChange={(value) => onUpdateMainData("applicableBusinessPractices", value)}
            validators={[new RequiredValidator("Applicable business practices is required")]}
            disabled={fetchError || disabled}
          />
          {fetchError && (
            <div className="color-red-heavy fs-small m-top-10 fw-500">{t(SERVER_ERROR_MESSAGE)}</div>
          )}
        </div>
      )}

      <div>
        <Select
          onChange={(value) => onUpdateMainData("mainBusinessActivity", value)}
          disabled={fetchError || disabled}
          alternatives={serverAlternatives.mainBusinessActivityAlts}
          value={mainData.mainBusinessActivity}
          label={t("The main business activity the company conduct")}
          validators={[new RequiredValidator(t("Main business activity is required"))]}
        />
        {fetchError && (
          <div className="color-red-heavy fs-small m-top-10 fw-500">{t(SERVER_ERROR_MESSAGE)}</div>
        )}
      </div>
    </div>
  );
};

export default CompanyDataLumaInputs;

const mapValuesToAlternatives = (values: string[]): Alternative<string>[] =>
  values.map((value) => ({ value, text: value }));

/* enum CustomerOrParentType {
  RegulatedFinancialInstitutions = "Regulated Financial Institutions, from a low risk jurisdiction",
  PubliclyListedCompanies = "Publicly Listed Companies, on an EU-recognised stock exchange",
  GovernmentOwnedEntities = "Government Owned Entities, from a low risk jurisdiction",
  RegulatedEntities = "Regulated entities, from a low risk jurisdiction (e.g. regulated charities)",
  TrustFundCharityClubSociety = "Trust; Fund; Charity; Club & Society",
  Other = "Other",
}

enum PurposeOfRelationshipType {
  SalesOfGoodsOrService = "Sales of goods or service",
  Donations = "Donations",
  InternationalMoneyTransfer = "(International) Money Transfer",
  ThirdPartySaleFacilitation = "Facilitating third party sale of goods or services",
  PaymentFacilitators = "Payment Facilitators",
  Reselling = "Reselling",
  OCTTransactions = "OCT original credit transactions",
  Other = "Other",
}

enum IntendedUseOfProductsType {
  InStorePOS = "In-store/point of sales (POS)",
  CallCentreMailOrder = "Call Centre Application, Mail Order/Telephone Order",
  AppLinkplusBillingPlan = "App, Linkplus, Billing and Settlement plan",
}

enum ApplicableBusinessPracticesType {
  NA = "N/A",
  Reselling = "Reselling",
  DropShipping = "Drop Shipping",
  MultiLevelMarketing = "Multi Level Marketing",
  StoredValueItems = "Stored Value Items",
  PaymentAggregation = "Payment Aggregation",
  CreatorEconomyUGC = "Creator Economy/UGC",
  HealthcareServices = "Healthcare/Medical/Dental Services",
  HighValueRetail = "High Value Retail",
} */
