import { Country, UTCDate } from "../model/common/commonType";
import { DateTimeFormatOptions } from "luxon";
import { nanoid } from "nanoid";
import { TFunction } from "react-i18next";
import { ContractDocument, MerchantCategory, Saluation } from "../model/contract/contractType";
import { Dynamic, DynamicOfType } from "../data/types";
import { defaultLanguageFromCountry, Language, TRANSLATION_NAMESPACE } from "../i18n";
import countries from "../lang/countries.json";
import { Alternative } from "./interactions/InputTypes";
import { Associate } from "../model/associate/associateTypes";
import { API } from "../network/API";
import { ContractStatusState } from "../state/contractStatusState";
import { MIME_PDF, FileType } from "./fileUpload/FileUpload";

export const MISSING_DATE = " - ";

const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * ONE_MINUTE;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_WEEK * 4;

export function id() {
  return nanoid();
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
export function returnFileSize(size: number) {
  if (size < 1024) {
    return size + "bytes";
  } else if (size >= 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + "KB";
  } else if (size >= 1048576) {
    return (size / 1048576).toFixed(1) + "MB";
  }
}

export function trimAllButAlphaNumeric(value?: string) {
  if (!value) {
    return "";
  }

  return value.replaceAll(/[\W_]+/g, "");
}

export function getDynamicLink(dynamicUrl: string, dynamicValues: Dynamic) {
  let url = dynamicUrl;
  Object.keys(dynamicValues).forEach((key) => {
    const regEx = new RegExp(`:${key}(\\?)?`, "gi");
    url = url.replace(regEx, dynamicValues[key]);
  });
  return url;
}

interface CasType {
  cas?: number;
  [key: string]: any;
}

export function clearCas(data: CasType | CasType[]) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const copy = { ...item };
      delete copy.cas;
      return copy;
    });
  }

  const copy = { ...data };
  delete copy.cas;
  return copy;
}

export function getLanguageOpts(lang: Language, country: Country, t: any, allowEmpty: boolean = true) {
  const regionNames = new (Intl as any).DisplayNames([lang], {
    type: "language",
  });

  const languageList = [];

  if (allowEmpty) {
    languageList.push({
      value: undefined,
      text: "-",
      disabled: false,
    });
  } else {
    languageList.push({
      value: undefined,
      text: t("Language"),
      disabled: true,
    });
  }

  // Allow only language of current country and English
  const langForCountry = defaultLanguageFromCountry(country);

  languageList.push({
    value: langForCountry,
    text: regionNames.of(langForCountry),
    disabled: false,
  });

  languageList.push({
    value: Language.UK,
    text: regionNames.of(Language.UK),
    disabled: false,
  });

  if (lang === Language.FRANCE) {
    languageList.push({
      value: Language.FRANCE,
      text: regionNames.of(Language.FRANCE),
      disabled: false,
    });
  }

  return languageList;
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function getCountryOpts(lang: Language, t: TFunction<"translation">, allowEmpty: boolean = false) {
  const regionNames = new (Intl as any).DisplayNames([lang], {
    type: "region",
  });

  const countryList = Object.entries(countries).map(
    (country): Alternative<string | undefined> => ({
      value: country[0],
      text: regionNames.of(country[0]),
      disabled: false,
    })
  );

  if (allowEmpty) {
    countryList.unshift({
      value: undefined,
      text: t("None"),
      disabled: false,
    });
  } else {
    countryList.unshift({
      value: undefined,
      text: t("Country"),
      disabled: true,
    });
  }

  return countryList;
}

export function getLatestValueByKey<T>(key: keyof T, values: T[]) {
  return sortLatestByKey(key, values)[0];
}

export function sortLatestByKey<T>(key: keyof T, values: T[]) {
  // don't modify original array
  const copy = [...values];
  copy.sort((a: T, b: T) => {
    const aValue = a[key];

    if (typeof aValue === "string") {
      return new Date(b[key] as unknown as string).getTime() - new Date(aValue).getTime();
    }

    if (typeof (aValue as unknown as Date).getMonth === "function") {
      return (b[key] as unknown as Date).getTime() - (aValue as unknown as Date).getTime();
    }

    return 0;
  });

  return copy;
}

export function getFunctionOpts(t: TFunction<"translation">) {
  const functionsList = [
    {
      value: "ADMINISTRATOR",
      text: "Administrator",
      disabled: false,
    },
    {
      value: "ASSISTANT",
      text: "Assistant",
      disabled: false,
    },
    {
      value: "CHARGEBACK",
      text: "Chargeback",
      disabled: false,
    },
    {
      value: "CHIEFEXECOFF",
      text: "Chief Executive Officer",
      disabled: false,
    },
    {
      value: "DIRECTOR",
      text: "Director",
      disabled: false,
    },
    {
      value: "EMPLOYEE",
      text: "Employee",
      disabled: false,
    },
    {
      value: "FRAUD",
      text: "Fraud",
      disabled: false,
    },
    {
      value: "GROUPLEADER",
      text: "Group leader",
      disabled: false,
    },
    {
      value: "HDOFDPRTMT",
      text: "Head of Department",
      disabled: false,
    },
    {
      value: "MANAGER",
      text: "Manager",
      disabled: false,
    },
    {
      value: "MBROFBOARD",
      text: "Member of board",
      disabled: false,
    },
    {
      value: "MBROFMGMT",
      text: "Member of managment",
      disabled: false,
    },
    {
      value: "OWNER",
      text: "Owner",
      disabled: false,
    },
    {
      value: "OWNERMANAGER",
      text: "Owner and manager",
      disabled: false,
    },
    {
      value: "PROJECTLEADER",
      text: "Project leader",
      disabled: false,
    },
    {
      value: "RESPONSIBLEOFF",
      text: "Responsible officer",
      disabled: false,
    },
    {
      value: "SECTORLEADER",
      text: "Sector leader",
      disabled: false,
    },
    {
      value: "TENANT",
      text: "Tenant",
      disabled: false,
    },
  ];

  functionsList.unshift({
    value: "NONE",
    text: t("Select function"),
    disabled: true,
  });

  return functionsList;
}

export function getMccOpts(categories: MerchantCategory[], t?: TFunction) {
  return categories.map((category) => ({
    value: category.code,
    text: `${category.code} - ${t ? t(category.name, { ns: TRANSLATION_NAMESPACE.MCC }) : category.name}`,
    disabled: false,
  }));
}

export function getIntlNumberFormat(lang: string, number: number, decimals: number = 0) {
  return new Intl.NumberFormat(lang, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function getIntlCost(lang: string, number: number, currency: string, decimals: number = 0) {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export const isStaleCase = (date: Date | string | UTCDate) => {
  if (!date) return true;

  const currentDate = new Date();
  const inputDate = new Date(date);

  const differenceInMs = currentDate.getTime() - inputDate.getTime();
  const differenceInSeconds = Math.floor(differenceInMs / 1000);

  const differenceInWeeks = differenceInSeconds / ONE_WEEK;

  // cases older than 2 weeks are considered stale
  return differenceInWeeks > 3;
};

export function getIntlDate(lang: string, date?: Date | string | UTCDate, options?: DateTimeFormatOptions) {
  if (!date) return MISSING_DATE;

  const dateObject = new Date(date);

  if (dateObject instanceof Date && !isNaN(dateObject as any)) {
    return new Intl.DateTimeFormat(lang, options).format(dateObject);
  } else {
    return MISSING_DATE;
  }
}

export function getRelativeDate(lang: string, date?: UTCDate) {
  if (!date) return MISSING_DATE;
  const dateNoTimezone = new Date(date);

  const now = new Date();

  // We got a problem since we dont save the time zone to the database.
  // We always recieve dates in the format of "2024-05-16T12:09:01.104748Z" where Z stands for Z (zero timezone) which is incorrect.
  // Therefore we add the timezoneOffset from the frontend to the date to get the correct value
  const timezoneOffset = now.getTimezoneOffset() * 60 * 1000;
  const dateWithTimezone = new Date(dateNoTimezone.getTime() + timezoneOffset);

  if (dateWithTimezone instanceof Date && !isNaN(dateWithTimezone as any)) {
    const diff = now.getTime() - dateWithTimezone.getTime();
    const seconds = Math.floor(diff / 1000);

    const rtf = new Intl.RelativeTimeFormat(lang);

    const secondFormat = seconds < ONE_MINUTE;
    const minuteFormat = seconds < ONE_HOUR;
    const hourFormat = seconds < ONE_DAY;
    const daysFormat = seconds < ONE_MONTH;

    if (secondFormat) {
      return rtf.format(-seconds, "second");
    }

    if (minuteFormat) {
      const minutes = Math.floor(seconds / ONE_MINUTE);
      return rtf.format(-minutes, "minute");
    }

    if (hourFormat) {
      const hours = Math.floor(seconds / ONE_HOUR);
      return rtf.format(-hours, "hour");
    }

    if (daysFormat) {
      const days = Math.floor(seconds / ONE_DAY);
      return rtf.format(-days, "day");
    }

    // over 4 weeks, 1 month
    const months = Math.floor(seconds / ONE_MONTH);
    return rtf.format(-months, "month");
  }

  return MISSING_DATE;
}

export function convertArrayToObjectByKey<T>(array: T[], key: string) {
  const item: DynamicOfType<T> = {};
  return array.reduce((acc, curr) => {
    acc[(curr as any)[key] as string] = curr;
    return acc;
  }, item);
}

export function convertDynamicObjectToArray<T>(obj: any): T[] {
  return Object.keys(obj).map((key) => obj[key]);
}

export const getCurrencySymbol = (locale: string, currency: string) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

export const getFullname = (associate: Associate | undefined) => {
  if (!associate) return null;

  return `${associate.lastName}, ${associate.firstName} `;
};

export const getPronoun = (associate: Associate | undefined, type: "subject" | "object" = "subject") => {
  if (!associate) return;
  if (associate.saluation === Saluation.HE && type === "subject") return "he";
  if (associate.saluation === Saluation.HE && type === "object") return "him";
  if (associate.saluation === Saluation.SHE && type === "subject") return "she";
  if (associate.saluation === Saluation.SHE && type === "object") return "her";
  return "them";
};

export const deepCopy = <T>(obj: T): T => {
  const deepCopy = JSON.parse(JSON.stringify(obj)) as T;
  return deepCopy;
};

export const getCountryDisplayString = (country: Country) => {
  switch (country) {
    case Country.POLAND:
      return "Poland";
    case Country.CROATIA:
      return "Croatia";
    case Country.CZECHIA:
      return "Czechia";
    default:
      return country;
  }
};

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

function getFileUrl(contract: ContractStatusState, document: ContractDocument) {
  if (document.fileAvailable) {
    return `${API.getUrl(`/api/sales/contracts/${contract.contractId}/document/${document.id}`)}?q=${
      document.uploaded
    }`;
  }
}

export function getFileAttributes(contract: ContractStatusState, document: ContractDocument) {
  return {
    url: getFileUrl(contract, document),
    type: document.mimeType === MIME_PDF ? FileType.PDF : FileType.IMAGE,
  };
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}
