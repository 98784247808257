import React, { useState, useCallback, useMemo, useRef } from "react";
import { Address, ContractType, DocumentType } from "../../../../../model/contract/contractType";
import { contractStatusState } from "../../../../../state/contractStatusState";
import { COPY_TYPE, CopyFrom } from "../../../../../components/copyFrom/CopyFrom";
import { dataContracts } from "../../../../../data/dataContracts";
import { Status } from "../../../../../data/types";
import { ContractEditError } from "../../../ContractEditError";
import { ContractSaveError, getEditStatus, handleError } from "../../../ContractPage";
import { HeaderWithCopy } from "../../../../../components/copyFrom/HeaderWithCopy";
import { Contact } from "../../../../../components/contact/Contact";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Terminals } from "./Terminals/Terminals";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { contractPackageState } from "../../../../../state/contractPackageState";
import { contractStoresState } from "../../../../../state/contractStoresState";
import { AssociateId, Cas, DocumentId } from "../../../../../model/common/commonType";
import { contractSaveState, contractErrorState } from "../../../../../state/contractSaveState";
import { storeQueue } from "../../../../../data/queues/StoreQueue";
import { useTranslation } from "react-i18next";
import { Form, FormContainer, shouldSaveOnBlur } from "../../../../../components/form/Form";
import { TextInput } from "../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { FormName } from "../../../menus/ContractEditMenu";
import { contractMainContractDataState } from "../../../../../state/contractMainContractDataState";
import { clearCas } from "../../../../../components/utils";
import { Associate } from "../../../../../model/associate/associateTypes";
import { ONGOING_RESPONSE } from "../../../../../data/queues/QueueTypes";
import { Store2 } from "../../../../../model/contract/contractType";
import "./StoreForm.scss";
import useContractData from "../../../../../hooks/useContractData";
import { MinLengthValidator } from "../../../../../components/form/validators/MinLengthValidator";
import { PhoneCountryCodeValidator } from "../../../../../components/form/validators/PhoneCountryCodeValidator";
import { AddressWithSearch } from "../../../../../components/address/AddressWithSearch.module";
import StoreFormTerminals from "../StoreFormTerminals/StoreFormTerminals";
import useSavePricingStructure from "../../../pricingV2/useSavePricingStructure";
import { Select } from "../../../../../components/form/Select";
import { NumberValidator } from "../../../../../components/form/validators/NumberValidator";
import { useDebounceFn } from "../../../../../hooks/useDebounce";
import { TextArea } from "../../../../../components/form/TextArea";
import {
  LumaFieldName,
  lumaFieldnameSelector,
  lumaRequirementsState,
} from "../../../../../state/lumaRequirementsState";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";

interface Props {
  store: Store2<ContractType.INSTORE>;
  formContainer: React.MutableRefObject<FormContainer | undefined>;
}

const bogeyAssociate: Associate = {
  cas: 0 as Cas,
  associateId: "id" as AssociateId,
  documents: {
    idCard: {
      id: 3 as DocumentId,
      documentType: DocumentType.ID_DOCUMENT,
      fileAvailable: false,
    },
  },
  roles: [],
};

function clearStoreCas(store: Store2) {
  const terminalsCopy = clearCas(store.terminals);
  const storeCopy = clearCas(store);
  return {
    ...storeCopy,
    terminals: terminalsCopy,
  };
}

export const StoreForm: React.FunctionComponent<Props> = ({ store, formContainer }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const contractStatus = useRecoilValue(contractStatusState);
  const packageState = useRecoilValue(contractPackageState);
  const [, setStores] = useRecoilState(contractStoresState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const prevSaved = useRef<string>(JSON.stringify(clearStoreCas(store)));
  const { storeId } = store;
  const lumaFieldnames = useRecoilValue(lumaFieldnameSelector);
  const { error: lumaError } = useRecoilValue(lumaRequirementsState);

  const { contractPricing } = useSavePricingStructure();
  const { isAdvancedPricing, isLumaContract } = useContractData();

  const instoreContractPricing = contractPricing.INSTORE;
  const displayTerminals = instoreContractPricing?.acceptance ? true : false;

  const ESTIMATE_NUMBER_TRANSACTIONS = useMemo(
    () => [
      {
        value: "Less than 100",
        text: t("Less than 100"),
      },
      {
        value: "100 to 250",
        text: t("100 to 250"),
      },
      {
        value: "251 to 1.000",
        text: t("251 to 1.000"),
      },
      {
        value: "1.001 to 5.000",
        text: t("1.001 to 5.000"),
      },
      {
        value: "5.001 to 20.000",
        text: t("5.001 to 20.000"),
      },
      {
        value: "20.001 to 100.000",
        text: t("20.001 to 100.000"),
      },
      {
        value: "More than 100.000",
        text: t("More than 100.000"),
      },
    ],
    [t]
  );

  const ESTIMATE_ANNUAL_TURNOVER = useMemo(
    () => [
      {
        value: "Less than 5.000",
        text: t("Less than 5.000"),
      },
      {
        value: "5.000 to 12.000",
        text: t("5.000 to 12.000"),
      },
      {
        value: "12.001 to 25.000",
        text: t("12.001 to 25.000"),
      },
      {
        value: "25.001 to 50.000",
        text: t("25.001 to 50.000"),
      },
      {
        value: "50.001 to 200.000",
        text: t("50.001 to 200.000"),
      },
      {
        value: "200.001 to 1.000.000",
        text: t("200.001 to 1.000.000"),
      },
      {
        value: "More than 1.000.000",
        text: t("More than 1.000.000"),
      },
    ],
    [t]
  );

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const onChangeLocationData = useCallback(
    <K extends keyof Store2>(key: K, value: Store2[K]) => {
      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === storeId) {
            return {
              ...prevStore,
              [key]: value,
            };
          }
          return prevStore;
        })
      );
    },
    [setStores, storeId]
  );

  const saveCallback = useCallback(
    (err, response) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      setStatus(Status.DEFAULT);

      if (err) {
        prevSaved.current = "";
        handleError(err, setError);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
          })
        );
        return;
      }

      setStores(response as Store2[]);
      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
    },
    [setDataSaved, setDataError, setStores]
  );

  const onSave = useCallback(
    (saveStore: Store2) => {
      const stringCopy = JSON.stringify(clearCas(saveStore));
      if (prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      setStatus(Status.PENDING);
      storeQueue.saveStore(contractStatus.contractId, saveStore, saveCallback);
    },
    [contractStatus.contractId, saveCallback]
  );

  const debounceSave = useDebounceFn(onSave, 500);

  const setTestStore = useCallback(
    <K extends keyof Store2>(key: K, value: Store2[K]) => {
      let updatedStore;
      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === store.storeId) {
            updatedStore = {
              ...prevStore,
              [key]: value,
            };
            return updatedStore;
          }
          return prevStore;
        })
      );
      updatedStore && debounceSave(updatedStore);
    },
    [setStores, debounceSave, store.storeId]
  );

  const trySave = useCallback(() => {
    onSave(store);
  }, [onSave, store]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(trySave, 500);
  }, [trySave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(trySave).catch(trySave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, trySave]);

  const setContact = useCallback(
    (associate, name?, ev?) => {
      let updatedStore;

      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === storeId) {
            updatedStore = {
              ...prevStore,
              contact: {
                firstName: associate.firstName,
                lastName: associate.lastName,
                email: associate.email,
                phoneNumber: associate.phoneNumber,
                saluation: associate.saluation,
              },
            };
            return updatedStore;
          }
          return prevStore;
        })
      );

      if (shouldSaveOnBlur(ev?.target)) {
        return;
      }

      updatedStore && onSave(updatedStore);
    },
    [setStores, onSave, storeId]
  );

  const setAddress = useCallback(
    (associate, name?, ev?) => {
      let updatedStore;

      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === storeId) {
            updatedStore = {
              ...prevStore,
              address: associate.address,
            };
            return updatedStore;
          }
          return prevStore;
        })
      );

      if (shouldSaveOnBlur(ev?.target)) {
        return;
      }

      updatedStore && onSave(updatedStore);
    },
    [setStores, storeId, onSave]
  );

  const setPhoneNumber = useCallback(
    (storePhone, name?, ev?) => {
      let updatedStore;

      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === storeId) {
            updatedStore = {
              ...prevStore,
              storePhone: storePhone,
            };
            return updatedStore;
          }
          return prevStore;
        })
      );

      if (shouldSaveOnBlur(ev?.target)) {
        return;
      }

      updatedStore && onSave(updatedStore);
    },
    [setStores, storeId, onSave]
  );

  const onCopy = useCallback(
    (type, value) => {
      // Well for obvious reasons we won't get the "blur" event
      // when copying so we need to save manually
      let copy: Store2;

      if (type === COPY_TYPE.ADDRESS) {
        copy = { ...store, address: value };
        setAddress({ address: value });
      } else if (type === COPY_TYPE.PHONENUMBER) {
        copy = { ...store, storePhone: value.phoneNumber };
        setPhoneNumber(value.phoneNumber);
      } else {
        copy = {
          ...store,
          contact: {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phoneNumber: value.phoneNumber,
            saluation: value.saluation,
          },
        };
        setContact(value);
      }
      onSave(copy);
    },
    [setAddress, setContact, store, onSave, setPhoneNumber]
  );

  const copyCommercialName = () => {
    if (!mainData.cardholderStatementText) {
      return;
    }

    const copy: Store2 = {
      ...store,
      commercialName: mainData.cardholderStatementText,
    };
    onChangeLocationData("commercialName", mainData.cardholderStatementText);
    onSave(copy);
  };

  const onAddressChange = useCallback(
    (address: Address) => {
      let updatedStore;

      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === storeId) {
            updatedStore = { ...prevStore, address: address };
            return updatedStore;
          }
          return prevStore;
        })
      );

      updatedStore && onSave(updatedStore);
    },
    [onSave, setStores, storeId]
  );

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, Status.DEFAULT);
  }, [contractStatus.edit]);

  return (
    <div className="store">
      <Form
        onSaveTrigger={(event, form) => {
          const realErrors = hasRealErrors(form);
          if (!realErrors) {
            onSave(store);
          }
        }}
        name={FormName.STORE}
        formContainer={formContainer}
      >
        <ContractEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />

        <div className="double-auto-columns">
          <div>
            <TextInput
              onChange={(value) => onChangeLocationData("commercialName", value)}
              name="commercialName"
              value={store.commercialName}
              label={t("Commercial name")}
              validators={[
                new RequiredValidator(t("Commercial name is required")),
                new MaxLengthValidator(
                  30,
                  t("Commercial name cannot be longer than {max} characters", { max: 30 })
                ),
              ]}
              disabled={!contractStatus.edit || status === Status.DISABLED}
            />
          </div>

          {mainData.cardholderStatementText && !isAdvancedPricing && (
            <div className="commercial-name-wrapper">
              <>
                <Button
                  className="ghost"
                  onClick={copyCommercialName}
                  status={inputStatus}
                  data-testid="copy-commercial-name"
                >
                  Copy from
                </Button>

                <div className="truncated">
                  <b>Short address:</b>
                  <br />
                  <i>{mainData.cardholderStatementText}</i>
                </div>
              </>
            </div>
          )}

          {isAdvancedPricing && (
            <div>
              <TextInput
                onChange={(value) => onChangeLocationData("cardStatementName", value)}
                label={t("Short address")}
                hint={t("For cardholder statement")}
                placeholder={t("Max 21 characters")}
                value={store.cardStatementName}
                name="cardStatementName"
                validators={[
                  new RequiredValidator(t("Short adress is required")),
                  new MaxLengthValidator(
                    21,
                    t("Short adress cannot be longer than {{min}} characters", {
                      min: 21,
                    })
                  ),
                ]}
                disabled={!contractStatus.edit || status === Status.DISABLED}
              />
            </div>
          )}

          <TextInput
            onChange={(value) => onChangeLocationData("storePhone", value)}
            name="storePhone"
            value={store.storePhone}
            label={t("Customer Service Phone")}
            hint={t("Customer Service Phone will be presented on transaction")}
            validators={[
              new RequiredValidator(t("Customer Service Phone number is required")),
              new PhoneCountryCodeValidator(t("Phone number must start with a country code e.g +46...")),
              new MinLengthValidator(
                8,
                t("Customer Service Phone must be at least {{min}} characters", {
                  min: 8,
                })
              ),
              new MaxLengthValidator(
                14,
                t("Customer Service Phone must be less than {{max}} characters", {
                  max: 14,
                })
              ),
            ]}
            disabled={!contractStatus.edit || status === Status.DISABLED}
          />

          <div className="m-bottom-30">
            <CopyFrom onChange={onCopy} type={COPY_TYPE.PHONENUMBER} status={status} />
          </div>
        </div>

        <div>
          <div className="m-bottom-20">
            <HeaderWithCopy header={t("Location address")} onChange={onCopy} type={COPY_TYPE.ADDRESS} />
          </div>

          <div className="m-bottom-40">
            <AddressWithSearch
              label=""
              address={store.address}
              onChange={onAddressChange}
              addressRequiredFields={{
                street: true,
                city: true,
                postalCode: true,
                countryCode: true,
                streetNumber: true,
              }}
              disabled={!contractStatus.edit}
              country={contractStatus.country}
            />
          </div>

          {!isAdvancedPricing && (
            <Terminals
              terminals={store.terminals}
              packageState={packageState}
              inputStatus={inputStatus}
              store={store}
              saveStore={onSave}
            />
          )}

          <div className="m-bottom-40 ">
            <HeaderWithCopy header={t("Contact at location")} onChange={onCopy} type={COPY_TYPE.ASSOCIATE} />
          </div>

          <div className="double-auto-columns m-bottom-40">
            <Contact
              associate={{
                ...bogeyAssociate,
                ...store.contact,
              }}
              onChange={setContact}
              disabled={!contractStatus.edit}
              contactRequiredFields={{
                email: true,
                phone: true,
                firstName: true,
                lastName: true,
                salutation: true,
              }}
            />
          </div>

          {isLumaContract && lumaError ? (
            <ErrorBox className="m-bottom-40">
              {t("Could not fetch fields data for Luma. Please reload the page and try again.")}
            </ErrorBox>
          ) : (
            <>
              <div className="double-auto-columns m-y-40">
                {lumaFieldnames.includes(LumaFieldName.ProductServiceSoldViaWL) && (
                  <TextArea
                    onChange={(value) => setTestStore("wlProduct", value)}
                    name="wlProduct"
                    label={t("What product/service will be sold?")}
                    value={store.wlProduct}
                    validators={[
                      new RequiredValidator(t("Product/service is required")),
                      new MaxLengthValidator(
                        50,
                        t("Product/service cannot be longer than {{min}} characters", {
                          min: 50,
                        })
                      ),
                    ]}
                    disabled={!contractStatus.edit}
                  />
                )}

                <div />
                {lumaFieldnames.includes(LumaFieldName.MaximumTransactionValue) && (
                  <TextInput
                    onChange={(value) => setTestStore("maxTransactionValue", value)}
                    name="maxTransactionValue"
                    label={t("Max transaction value")}
                    value={store.maxTransactionValue}
                    validators={[
                      new RequiredValidator(t("Max transaction value is required")),
                      new NumberValidator(t("Max transaction value must be a number")),
                      new MaxLengthValidator(
                        10,
                        t("Max transaction value cannot be longer than {{min}} characters", {
                          min: 10,
                        })
                      ),
                    ]}
                    disabled={!contractStatus.edit}
                  />
                )}

                {lumaFieldnames.includes(LumaFieldName.AverageTransactionValue) && (
                  <TextInput
                    onChange={(value) => setTestStore("avgTransactionValue", value)}
                    name="avgTransactionValue"
                    label={t("Average transaction value")}
                    value={store.avgTransactionValue}
                    validators={[
                      new RequiredValidator(t("Average transaction value is required")),
                      new NumberValidator(t("Average transaction value must be a number")),
                      new MaxLengthValidator(
                        10,
                        t("Average transaction value cannot be longer than {{min}} characters", {
                          min: 10,
                        })
                      ),
                    ]}
                    disabled={!contractStatus.edit}
                  />
                )}

                {lumaFieldnames.includes(LumaFieldName.EstimatedTransactionCountPerYear) && (
                  <Select
                    onChange={(value) => setTestStore("estimateTransactionsYear", value)}
                    disabled={!contractStatus.edit}
                    label={t("Estimate number of transactions per year")}
                    alternatives={ESTIMATE_NUMBER_TRANSACTIONS}
                    value={store.estimateTransactionsYear}
                    validators={[new RequiredValidator(t("Estimate number of transactions is required"))]}
                  />
                )}

                {lumaFieldnames.includes(LumaFieldName.EstimatedAnnualTurnoverSalesLocation) && (
                  <Select
                    onChange={(value) => setTestStore("estimateTurnoverYear", value)}
                    disabled={!contractStatus.edit}
                    label={t("Estimate annual turnover by location")}
                    alternatives={ESTIMATE_ANNUAL_TURNOVER}
                    value={store.estimateTurnoverYear}
                    validators={[new RequiredValidator(t("Estimate annual turnover is required"))]}
                  />
                )}
              </div>
            </>
          )}

          {isAdvancedPricing && displayTerminals && <StoreFormTerminals terminals={store.terminals} />}
        </div>
      </Form>
    </div>
  );
};
