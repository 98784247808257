import { useEffect, useState, useCallback } from "react";
import { generatePath, useHistory, useLocation } from "react-router";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { Spinner } from "../../components/spinner/Spinner";
import { T } from "../../components/translation/T";
import {
  CorrelationIdLookupResponse,
  CorrelationIdLookupStatus,
  dataContracts,
} from "../../data/dataContracts";
import { CONTRACT_ROUTE } from "../Contract/ContractPage";
import { PageStripped } from "../Page/PageStripped";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { CorrelationId } from "../../model/common/commonType";
import { GenericError } from "../../components/errors/GenericError";
import { DESKTOP_WIDTH } from "../../hooks/useWidth";
import { CreateContractWithCorrelationID } from "./CreateContractWithCorrelationID";
import { MERCHANT_ROUTE } from "../MerchantStatus/MerchantStatusPage";
import { COMPLETED_CONTRACT_PAGE } from "../CompletedContract/CompletedContractPage";
import { Button } from "../../components/interactions/Buttons/Button";
import { Trans } from "react-i18next";
import styles from "./CreateContractWithCorrelationID.module.scss";
import useCountdown from "../../hooks/useCountdown";

export const CORRELATION_ID_ROUTING_PAGE = "/salesforce";
export const CORRELATION_ID_KEY = "correlationId";
export const IS_PARTNER_KEY = "ispartner";

export function CorrelationIdRoutingPage() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const correlationId = queryParams.get(CORRELATION_ID_KEY);

  //   const setAuthState = useSetRecoilState(contractAuthState);
  //   const correlationId = params.get(CORRELATION_ID_KEY);
  //   const isPartner = !!params.get(IS_PARTNER_KEY);

  //   useEffect(() => {
  //     const { referrer } = document;

  //     Store.setValue(STORE_KEY.STORE_IS_PARTNER, isPartner);
  //     setAuthState((prev) => ({ ...prev, isPartner }));

  //     if (!referrer) {
  //       return;
  //     }

  //     Store.setValue(STORE_KEY.STORE_REFERRER, referrer);
  //     setAuthState((prev) => ({ ...prev, referrer }));
  //   }, [setAuthState, isPartner]);

  if (!correlationId) return <NoCorrelactionIDFound />;

  return <RoutingPageInner correlationId={correlationId as CorrelationId} />;
}

enum RoutingStatus {
  PENDING = "PENDING",
  ERROR = "ERROR",
  NEW = "NEW",
  REDIRECT = "REDIRECT",
}

function RoutingPageInner({ correlationId }: { correlationId: CorrelationId }) {
  const [status, setStatus] = useState<RoutingStatus>(RoutingStatus.PENDING);
  const history = useHistory();
  const [startCountdown, setStartCountdown] = useState(false); // State to control countdown
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  const redirectTime = useCountdown(5, startCountdown);

  const goToContract = () => {
    redirectUrl && history.push(redirectUrl);
  };

  const load = useCallback(() => {
    dataContracts
      .companyLookupByWithCorrelationId(correlationId)
      .then((data) => {
        // Store.setValue(STORE_KEY.STORE_CORRELATION_ID, correlationId);

        if (data.status === CorrelationIdLookupStatus.NEW) {
          return setStatus(RoutingStatus.NEW);
        }

        setStatus(RoutingStatus.REDIRECT);
        setRedirectUrl(getRouteByStatus(data));
        setStartCountdown(true);
      })
      .catch((err) => setStatus(RoutingStatus.ERROR));
  }, [correlationId]);

  useEffect(() => {
    load();
  }, [load]);

  const retry = useCallback(() => {
    setStatus(RoutingStatus.PENDING);

    setTimeout(() => {
      load();
    }, 500);
  }, [load]);

  if (redirectTime <= 0 && redirectUrl) {
    history.push(redirectUrl);
  }

  return (
    <PageStripped title="Checking contract status..." contentMaxWidth={DESKTOP_WIDTH}>
      <section>
        <article>
          <div className="m-top-40">
            <AnimateHeight name={status}>
              <div>
                {status === RoutingStatus.NEW && (
                  <CreateContractWithCorrelationID correlationId={correlationId} />
                )}

                {status === RoutingStatus.PENDING && (
                  <div className="center fw-500">
                    <T>Lookup contract status</T>
                    <Spinner size="large" className="m-top-40" />
                  </div>
                )}

                {status === RoutingStatus.ERROR && <GenericError onRetry={retry} />}

                {status === RoutingStatus.REDIRECT && (
                  <div className={styles.warning}>
                    <div className="m-bottom-30">
                      <Trans>
                        A contract with Correlation ID: <b>{{ correlationId }}</b> has already been started.
                      </Trans>
                    </div>

                    <div className="m-bottom-20 fw-500">
                      <Trans>
                        You will be redirected to the contract in <b>{{ redirectTime }} seconds</b>
                      </Trans>
                    </div>

                    <Button onClick={goToContract} block>
                      <T>Go to contract</T>
                    </Button>
                  </div>
                )}
              </div>
            </AnimateHeight>
          </div>
        </article>
      </section>
    </PageStripped>
  );
}

function getRouteByStatus(lookupResponse: CorrelationIdLookupResponse) {
  if (lookupResponse.status === CorrelationIdLookupStatus.SALES) {
    return generatePath(CONTRACT_ROUTE, {
      id: lookupResponse.contractId || "",
    });
  }

  if (lookupResponse.status === CorrelationIdLookupStatus.MERCHANT) {
    return generatePath(MERCHANT_ROUTE, {
      id: lookupResponse.contractId || "",
    });
  }

  if (lookupResponse.status === CorrelationIdLookupStatus.COMPLETE) {
    return generatePath(COMPLETED_CONTRACT_PAGE, {
      id: lookupResponse.contractId || "",
    });
  }

  throw new Error(`Unsupported status - ${lookupResponse.status}`);
}

const NoCorrelactionIDFound = () => {
  return (
    <PageStripped title="Checking contract status...">
      <section>
        <article>
          <div className="m-top-40">
            <ErrorBox>
              <T>The link is invalid. The url must include a correlation ID in order for us to proceed.</T>
            </ErrorBox>
          </div>
        </article>
      </section>
    </PageStripped>
  );
};
