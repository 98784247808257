import { useState, useEffect } from "react";

const useCountdown = (initialSeconds: number, start: boolean) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (start && seconds > 0) {
      // Countdown only if 'start' is true
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Clear interval on component unmount or when seconds change
      return () => clearInterval(timer);
    }
  }, [start, seconds]); // Add 'start' as a dependency

  return seconds;
};

export default useCountdown;
