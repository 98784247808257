import React, { ReactNode } from "react";
import { Status } from "../../data/types";
import { CasError } from "../../components/errors/CasError";
import { ContractSaveError } from "./ContractPage";
import { NewOverlay } from "../../components/overlay/NewOverlay";
import { ClaimError } from "../../components/errors/ClaimError";
import { GenericError } from "../../components/errors/GenericError";
import { DefaultError } from "../../components/errors/DefaultError";

interface Props {
  error: ContractSaveError | null;
  setError: (error: ContractSaveError) => void;
  retry: () => void;
  onClose: () => void;
  reclaimAndSave: () => void;
  children?: ReactNode;
}

const ContractEditErrorInner: React.FunctionComponent<Props> = ({
  error,
  setError,
  retry,
  onClose,
  reclaimAndSave,
  children,
}) => {
  if (error === ContractSaveError.CAS_ERROR) {
    return <CasError />;
  }

  if (error === ContractSaveError.CLAIM_ERROR) {
    return <ClaimError onClick={reclaimAndSave} />;
  }

  if (error === ContractSaveError.SERVER_ERROR) {
    return <GenericError onRetry={retry} />;
  }

  if (children) {
    return <>{children}</>;
  }

  return <DefaultError onClick={onClose} />;
};

export const ContractEditError: React.FunctionComponent<Props> = ({
  error,
  setError,
  retry,
  onClose,
  reclaimAndSave,
  children,
}) => {
  return (
    <div className="contract-edit-error">
      <NewOverlay open={error !== null} onClose={onClose} status={Status.ERROR} widthSize="small">
        <ContractEditErrorInner
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
          children={children}
        />
      </NewOverlay>
    </div>
  );
};
