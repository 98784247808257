import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { contractMainContractDataState } from "../state/contractMainContractDataState";
import { ContractFeature, ContractType } from "../model/contract/contractType";
import { contractStatusState } from "../state/contractStatusState";

const useContractData = () => {
  const { contractType } = useRecoilValue(contractMainContractDataState);
  const { contractId, country, edit } = useRecoilValue(contractStatusState);

  const enabledFeatures = useRecoilValue(contractStatusState).enabledFeatures;
  const isAdvancedPricing = useMemo(
    () => enabledFeatures.includes(ContractFeature.ADVANCED_PRICING),
    [enabledFeatures]
  );
  const isLumaContract = useMemo(
    () => enabledFeatures.includes(ContractFeature.LUMA_CONTRACT),
    [enabledFeatures]
  );

  const isEcomContract = useMemo(() => contractType.includes(ContractType.ECOMMERCE), [contractType]);
  const isInstoreContract = useMemo(() => contractType.includes(ContractType.INSTORE), [contractType]);
  const isOmniContract = useMemo(
    () => isEcomContract && isInstoreContract,
    [isEcomContract, isInstoreContract]
  );

  return {
    isEcomContract,
    isInstoreContract,
    isOmniContract,
    isAdvancedPricing,
    contractId,
    isLumaContract,
    country,
    edit,
  };
};

export default useContractData;
